import { Card, Typography } from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebase'
import {
  ClientsContext,
} from '../../contexts'
import { paymentDoc } from '../../types/paymentDoc'
import PaymentBlock from '../../components/PaymentBlock'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

export default function PrevPaymentsTab() {
  const clients = useContext(ClientsContext)

  const [payment_rows, set_payment_rows] = useState<undefined | paymentDoc[]>()
  async function getPayments() {
    var payments_temp: paymentDoc[]
    await getDocs(collection(db, 'payments')).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          var payment_doc: paymentDoc = {
            file_id: '000',
            budget_original: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
            budget_adjustments: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
            payments_recieved: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
            payments_applied: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
          }
          const data = doc.data()
          if (data !== undefined) {
            for (let i in data) {
              payment_doc[i] = data[i]
            }
            if (payments_temp !== undefined) {
              payments_temp.push(payment_doc)
            } else {
              payments_temp = [payment_doc]
            }
          }
        }
      })
      set_payment_rows(payments_temp)
      // set number of pages
      setNumPages(parseInt(String(payments_temp.length / resultsPerPage)))
    })
  }
  const [clientRefs, setClientRefs] = useState({})
  function setClientRefTable() {
    var client_refs = {} // file_id: clientName
    clients.forEach((client) => {
      if (client && client.file_id) {
        client_refs[client.file_id] = client.name
      }
    })
    setClientRefs(client_refs)
  }
  function getClientNameByFileID(file_id) {
    if (clientRefs[file_id]) {
      return clientRefs[file_id]
    } else {
      return 'Unreconized Client'
    }
  }
  function sortByClientName(rows: paymentDoc[]) {
    return rows.sort((a, b) => {
      return getClientNameByFileID(a.file_id).localeCompare(
        getClientNameByFileID(b.file_id)
      )
    })
  }
  const [numPages, setNumPages] = useState(0)
  const [resultsPageNum, setResultsPageNum] = useState(1)
  const [resultsPerPage, setResultsPerPage] = useState(10)
  function filterByPage(rows: paymentDoc[]) {
    var filtered_rows: paymentDoc[] = []
    rows.forEach((paymentDoc, index) => {
      if (
        Number(index / resultsPerPage) >= resultsPageNum - 1 &&
        Number(index / resultsPerPage) < resultsPageNum
      ) {
        filtered_rows.push(paymentDoc)
      }
    })
    return filtered_rows
  }
  const [firstLoad, setFirstLoad] = useState(true)
  // eslint-disable-next-line
  useEffect(() => {
    if (firstLoad) {
      getPayments()
      setClientRefTable()
      setFirstLoad(false)
    }
  })

  function increasePageNum() {
    if (resultsPageNum < numPages) {
      setResultsPageNum(resultsPageNum + 1)
      reloadPaymentsCard()
    }
  }
  function decreasePageNum() {
    if (resultsPageNum > 1) {
      setResultsPageNum(resultsPageNum - 1)
      reloadPaymentsCard()
    }
  }
  function reloadPaymentsCard() {
    setPaymentsCardKey((Math.random() + 1).toString(36).substring(7))
  }
  const [paymentsCardKey, setPaymentsCardKey] = useState('first_key')
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row justify-between align-bottom">
        <div>
          <Typography placeholder={'.'} variant="h5" className="p-3">
            Recent Payments
          </Typography>
        </div>
        <div>
          <div className="flex flex-row p-3">
            <ChevronLeftIcon className="h-6 w-6" onClick={decreasePageNum} />
            Page: {resultsPageNum} of {numPages}
            <ChevronRightIcon className="h-6 w-6" onClick={increasePageNum} />
          </div>
        </div>
      </div>
      <Card
        placeholder={'.'}
        className="overflow-auto rounded-lg border"
        key={paymentsCardKey}
      >
        <div className="p-2">
          {payment_rows !== undefined ? (
            <div>
              {filterByPage(sortByClientName(payment_rows)).map(
                (payment_doc: paymentDoc, index) => {
                  return (
                    <div key={'payment_block_' + index}>
                      <PaymentBlock payment_doc={payment_doc} />
                    </div>
                  )
                }
              )}
            </div>
          ) : (
            <div>No Payments Found</div>
          )}
        </div>
      </Card>
    </div>
  )
}

import { client } from "../types/client";
import { contacts } from "../types/contacts";
import { roles } from "../types/roles";

export default function findClientIdsForAccount(clients: client[], account_id: string, roles_only=false, contacts_only=false){
  var all_ids: string[] = []
  var client_ids_with_roles_ref: string[] = []
  var client_ids_with_contacts_ref: string[] = []

  clients.forEach((client: client)=>{
    const roles: roles  = client.roles
    Object.keys(roles).forEach((title)=>{
      if (roles[title].includes(account_id) && !client_ids_with_roles_ref.includes(account_id)) {
        client_ids_with_roles_ref.push(client.id)
      }
    })
    const contacts: contacts = client.contacts
    Object.keys(contacts).forEach((title)=>{
      if (contacts[title].includes(account_id) && !client_ids_with_contacts_ref.includes(account_id)) {
        client_ids_with_contacts_ref.push(client.id)
      }
    })
  })

  if (roles_only){
    return client_ids_with_roles_ref
  } else if (contacts_only) {
    return client_ids_with_contacts_ref
  } else {
    all_ids = client_ids_with_contacts_ref.concat(client_ids_with_roles_ref)
    return  all_ids
  }
}
import { doc, getDoc } from 'firebase/firestore'
import { paymentDoc } from '../../../../../types/paymentDoc'
import PaymentBlock from '../../../../../components/PaymentBlock'
import { db } from '../../../../../firebase'
import { useEffect, useState } from 'react'

export default function Payments({ client_id }) {
  const [paymentRecord, setPaymentRecord] = useState<paymentDoc | undefined>()
  const [paymentInfoNotFound, setPaymentInfoNotFound] = useState(true)

  async function fetchPayments(client_id) {
    try {
      // resetting payments to blank:
      setPaymentRecord({
        file_id: '000',
        budget_original: {
          '2024': {
            Jan: '0',
            Feb: '0',
            Mar: '0',
            Apr: '0',
            May: '0',
            Jun: '0',
            Jul: '0',
            Aug: '0',
            Sep: '0',
            Oct: '0',
            Nov: '0',
            Dec: '0',
          },
        },
        budget_adjustments: {
          '2024': {
            Jan: '0',
            Feb: '0',
            Mar: '0',
            Apr: '0',
            May: '0',
            Jun: '0',
            Jul: '0',
            Aug: '0',
            Sep: '0',
            Oct: '0',
            Nov: '0',
            Dec: '0',
          },
        },
        payments_recieved: {
          '2024': {
            Jan: '0',
            Feb: '0',
            Mar: '0',
            Apr: '0',
            May: '0',
            Jun: '0',
            Jul: '0',
            Aug: '0',
            Sep: '0',
            Oct: '0',
            Nov: '0',
            Dec: '0',
          },
        },
        payments_applied: {
          '2024': {
            Jan: '0',
            Feb: '0',
            Mar: '0',
            Apr: '0',
            May: '0',
            Jun: '0',
            Jul: '0',
            Aug: '0',
            Sep: '0',
            Oct: '0',
            Nov: '0',
            Dec: '0',
          },
        },
      })

      await getDoc(doc(db, 'payments', String(client_id))).then((doc) => {
        if (doc.exists()) {
          const data = doc.data()
          var payment_doc: paymentDoc = {
            file_id: '000',
            budget_original: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
            budget_adjustments: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
            payments_recieved: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
            payments_applied: {
              '2024': {
                Jan: '0',
                Feb: '0',
                Mar: '0',
                Apr: '0',
                May: '0',
                Jun: '0',
                Jul: '0',
                Aug: '0',
                Sep: '0',
                Oct: '0',
                Nov: '0',
                Dec: '0',
              },
            },
          }
          for (let key in data) {
            payment_doc[key] = data[key]
          }
          setPaymentRecord(payment_doc)
          setPaymentInfoNotFound(false)
        }
      })
    } catch (err) {
      console.log('Error when loading payments:', err)
    }
  }

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      fetchPayments(client_id)
    }
  })

  return (
    <div className="h-full w-fit overflow-auto p-4">
      {!paymentInfoNotFound ? (
        <div className="min-h-40 h-fit w-full min-w-fit rounded-lg border border-gray-300  shadow-md ">
          {paymentRecord !== undefined ? (
            <PaymentBlock payment_doc={paymentRecord} />
          ) : (
            <div className="p-4">
              An error occured when fetching payment information for this
              client.
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="p-12">No Payment Information Found.</div>
        </div>
      )}
    </div>
  )
}

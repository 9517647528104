import { Chip, IconButton, Typography } from '@material-tailwind/react'
import { client } from '../../../types/client'
import { permission } from '../../../types/permission'
import { Link } from 'react-router-dom'
import { ClipboardDocumentIcon, PencilIcon } from '@heroicons/react/24/outline'

export default function ClientHeader(props: {
  clientDoc: client
  editMode: boolean
  permissions: permission
  isMyClient: boolean
}) {
  const client = props.clientDoc
  const editMode = props.editMode

  const fields = {
    file_id: {
      title: 'File ID',
      key: 'file_id',
    },
    orbit_id: {
      title: 'Orbit ID',
      key: 'id',
    },
    active: {
      title: 'Active',
      key: 'active',
    },
    potential_client: {
      title: 'Potential Client',
      key: 'potential_client',
    },
    start_date: {
      title: 'Start Date',
      key: 'start_date',
    },
    end_date: {
      title: 'Term Date',
      key: 'end_date',
    },
    tier: {
      title: 'Tier',
      key: 'tier',
    },
  }
  function sortAlphabetical(array: string[]) {
    return array.sort((a, b) => {
      return a.localeCompare(b)
    })
  }
  function genInfoLine(field) {
    if (client[field.key] !== undefined) {
      if (typeof client[field.key] === 'object') {
        if (client[field.key].length > 0) {
          return (
            <div
              key={field.key}
              className="w-64 flex justify-between overflow-wrap"
            >
              <div
                key="field"
                className="border-b border-blue-gray-50 p-3 px-4"
              >
                <Typography
                  placeholder="."
                  variant="small"
                  color="blue-gray"
                  className="font-bold"
                >
                  {field.title}
                </Typography>
              </div>
              <div
                key="value"
                className="border-b border-blue-gray-50 p-3 px-4"
              >
                <Typography
                  placeholder="."
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {client[field.key].length > 0 ? (
                    <>
                      {sortAlphabetical(client[field.key]).map(
                        (value, index) => {
                          return (
                            <div
                              className={String(value) + '_' + String(index)}
                            >
                              {value + ', '}
                              <br></br>
                            </div>
                          )
                        }
                      )}
                    </>
                  ) : (
                    <>{client[field.key]}</>
                  )}
                </Typography>
              </div>
            </div>
          )
        } else {
          return <></>
        }
      } else {
        if (!(client[field.key] === '')) {
          return (
            <div>
              <tr key={field.key} className="w-64">
                <td
                  key="field"
                  className="border-b border-blue-gray-50 p-3 px-4"
                >
                  <Typography
                    placeholder="."
                    variant="small"
                    color="blue-gray"
                    className="font-bold"
                  >
                    {field.title}
                  </Typography>
                </td>
                <td
                  key="value"
                  className="border-b border-blue-gray-50 p-2 px-4"
                >
                  <Typography
                    placeholder="."
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {client[field.key]}
                  </Typography>
                </td>
              </tr>
            </div>
          )
        } else {
          return <></>
        }
      }
    } else {
      return <></>
    }
  }
  function chipInfoLine(field, type) {
    return (
      <tr key="active">
        <td key="field" className="border-b border-blue-gray-50 p-3 px-4">
          <Typography
            placeholder="."
            variant="small"
            color="blue-gray"
            className="font-bold"
          >
            {field.title}
          </Typography>
        </td>
        <td key="value" className="border-b border-blue-gray-50 p-2 px-4">
          <Typography
            placeholder="."
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            {type === 'active' ? (
              <>
                {client.active ? (
                  <Chip
                    variant="ghost"
                    color="green"
                    size="sm"
                    value="Active"
                    icon={
                      <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-900 content-['']" />
                    }
                  />
                ) : (
                  <Chip
                    variant="ghost"
                    color="red"
                    size="sm"
                    value="In-Active"
                    icon={
                      <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-red-900 content-['']" />
                    }
                  />
                )}
              </>
            ) : (
              <>
                <Chip
                  variant="ghost"
                  color="blue"
                  size="sm"
                  value="True"
                  icon={
                    <span className="mx-auto mt-1 block h-2 w-2 rounded-full bg-green-900 content-['']" />
                  }
                />
              </>
            )}
          </Typography>
        </td>
      </tr>
    )
  }
  return (
    <>
      <div key="table_and_headers" className="h-fit w-full p-4 ">
        <div className="flex h-full flex-row justify-center flex-wrap">
          {client.logo !== '' ? (
            <div className="flex flex-col">
              <img
                className="h-40 min-w-20 object-scale-down"
                src={client.logo}
                alt={client.name}
              />
            </div>
          ) : (
            <>
              <Typography placeholder="." variant="h4" className="pb-2">
                {client.name}
              </Typography>
            </>
          )}
          <div className="flex flex-col justify-center px-6">
            <div className="w-full table-auto text-left">
              <div className=" flex max-h-32 flex-row flex-wrap">
                <div className="flex flex-col w-fit">
                  {genInfoLine(fields.file_id)}
                  {genInfoLine(fields.orbit_id)}
                  {props.permissions.view_tiers && (
                    <>{genInfoLine(fields.tier)}</>
                  )}
                </div>
                <div className="flex flex-col w-fit">
                  {client.potential_client &&
                    chipInfoLine(fields.potential_client, 'potential')}
                  {chipInfoLine(fields.active, 'active')}
                  {genInfoLine(fields.start_date)}
                  {genInfoLine(fields.end_date)}
                </div>
              </div>
            </div>
          </div>
          {editMode &&
            (props.permissions.edit_all_clients ||
              (props.permissions.edit_assigned_clients &&
                props.isMyClient)) && (
              <div className=" flex flex-row-reverse">
                <Link to={'/edit/client#' + String(client.id)}>
                  <IconButton placeholder="." variant="text">
                    <PencilIcon className="h-6 w-6"></PencilIcon>
                  </IconButton>
                </Link>
              </div>
            )}
        </div>
      </div>
    </>
  )
}

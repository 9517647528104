import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Button, Typography } from '@material-tailwind/react'
import { getAuth } from 'firebase/auth'
import { StatusContext } from '../../contexts'

export default function NotFound(): React.ReactElement {
  const status = useContext(StatusContext)
  const [goHome, setGoHome] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  function toggleGoHome() {
    if (goHome) {
      setGoHome(false)
    } else {
      setGoHome(true)
    }
  }
  function checkLoggedIn() {
    const auth = getAuth()
    const user = auth.currentUser
    if (user === null) {
      // user is not logged in.
      setGoHome(true)
    }
  }

  useEffect(() => {
    if (firstLoad) {
      checkLoggedIn()
      setFirstLoad(false)
    }
  })

  return (
    <div>
      {status === '' ? (
        <>
          <div className="p-10">
            {goHome && <Navigate to="/" replace={true} />}
            <Typography placeholder="404 Not Found" type="h1">
              404 Not Found.
            </Typography>
            <p className="py-10">Welcome to the not found page.</p>
            <Button placeholder="button" onClick={toggleGoHome}>
              Return Home
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="p-10">
            {goHome && <Navigate to="/" replace={true} />}
            <Typography placeholder="404 Not Found" type="h1">
              Orbit is currently unavailible.
            </Typography>
            {status === 'servicing' ? (
              <p className="py-10">
                The website has been taken down temporarily for maintanence.
              </p>
            ) : (
              <p className="py-10">
                The website is down for an unknown reason. This may be a network
                connectivity issue.
              </p>
            )}
          </div>
        </>
      )}
    </div>
  )
}

import { client } from '../../types/client'
import clientTemplate from './templateClient'

export default function ensureClientType(input_obj) {
  var client_template: client = clientTemplate()

  Object.keys(input_obj).forEach((key) => {
    client_template[key] = input_obj[key]
  })
  return client_template
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import { ThemeProvider } from '@material-tailwind/react'
import AuthAppWrapper from './AuthAppWrapper'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthAppWrapper />
    </ThemeProvider>
  </React.StrictMode>
)

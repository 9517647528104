import { useContext, useEffect, useState } from 'react'
import '../App.css'
import type { account } from '../types/account'
import type { client } from '../types/client'
import type { change } from '../types/change'
import Loading from '../components/Loading'
import { Navigate } from 'react-router-dom'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { AccountsContext, ClientsContext } from '../contexts'
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  Input,
  Radio,
  Typography,
} from '@material-tailwind/react'
import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../firebase'
import ensureChangeType from '../functions/ensureType/ensureChangeType'

export default function ChangeLog() {
  const accounts: account[] = useContext(AccountsContext)
  const clients: client[] = useContext(ClientsContext)
  const [searchOpen, setSearchOpen] = useState(false)
  function handleSearchOpen() {
    setSearchOpen(!searchOpen)
  }

  const [search_query, setQuery] = useState('')
  const [searchBy, setSearchBy] = useState('author')
  function search(changes) {
    switch (searchBy) {
      case 'author':
        // should return based off of query against name not id!
        return changes
          .filter((change) =>
            getAuthorName(change.author_id)
              .toString()
              .toLowerCase()
              .includes(search_query.toLowerCase())
          )
          .sort((a, b) => {
            return b.actionDate - a.actionDate
          })
      case 'subject':
        // should return based off of subject name
        return changes
          .filter((change) =>
            getSubjectName(change.subject_id, change.subject_type)
              .toString()
              .toLowerCase()
              .includes(search_query.toLowerCase())
          )
          .sort((a, b) => {
            return b.actionDate - a.actionDate
          })
      case 'content':
        // should return based off of the content of the change.
        return changes
          .filter((change) =>
            getContent(change)
              .toString()
              .toLowerCase()
              .includes(search_query.toLowerCase())
          )
          .sort((a, b) => {
            return b.actionDate - a.actionDate
          })
    }
  }
  function getAuthorName(author_id) {
    var name = ''
    for (let i in accounts) {
      if (accounts[i].id === author_id) {
        name = accounts[i].name
      }
    }
    return name
  }
  function getSubjectName(subject_id, subject_type) {
    var name = ''
    var collection: account[] | client[] = []
    if (subject_type === 'account') {
      collection = accounts
    } else {
      collection = clients
    }
    for (let i in collection) {
      if (collection[i].id === subject_id) {
        name = collection[i].name
      }
    }
    return name
  }
  function getContent(change) {
    var content = ''
    content = String(change.old_value) + String(change.new_value)
    return content
  }
  function handleChangeSearchBy(new_value) {
    setSearchBy(new_value)
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }

  const TABLE_HEAD = ['Author', 'Field', 'Before', 'After', 'Date']

  const [navPath, setNavPath] = useState('')
  const [redirectNow, setRedirectNow] = useState(true)
  function navigateToChange(change_type, change_id) {
    const path = '/view/' + String(change_type) + '#' + String(change_id)
    setNavPath(path)
    setRedirectNow(true)
  }
  const [changes, setChanges] = useState<change[]>()
  async function getChanges() {
    const allChanges = collection(db, 'change_log')
    const q = query(allChanges, orderBy("actionDate", "desc"), limit(25))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      var temp_changes: change[] = []
      querySnapshot.forEach((change_doc) => {
        temp_changes.push(ensureChangeType(change_doc.data()))
      })
      setChanges(temp_changes)
    })
  }

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      getChanges()
      setFirstLoad(false)
    }
  })
  return (
      <Card placeholder="." className="flex h-fit w-fit flex-row rounded-lg">
        {redirectNow === true && <Navigate replace to={navPath} />}
        {accounts !== undefined && (
          <>
            <div className="flex flex-grow basis-2/3 flex-col p-5">
              <div className="w-96 flex flex-row">
                <Input
                  crossOrigin={false}
                  label="Search"
                  value={search_query}
                  onChange={handleQuery}
                  icon={<MagnifyingGlassIcon />}
                />
              </div>
              <div className="py-5">
                <Card
                  placeholder="."
                  className="overflow-auto w-fit max-w-2/3 rounded-lg border border-gray-300 shadow-md"
                  style={{ height: '80vh' }}
                >
                  <table className="h-fit max-w-full min-w-fit text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {changes === undefined ? (
                      <>
                        <div className="p-3">
                          <Loading />
                        </div>
                      </>
                    ) : (
                      <>
                        {changes.length > 0 ? (
                          <tbody>
                            {search(changes).map(
                              (change: change, index: number) => {
                                const classes = 'p-2 overflow-x-auto border h-4'
                                const author_id = change.author_id
                                var field = ''
                                var subject_name = ''
                                var author_name = ''
                                var new_value = change.new_value
                                var old_value = change.old_value
                                for (let i in accounts) {
                                  if (accounts[i].id === author_id) {
                                    author_name = accounts[i].name
                                  }
                                  if (change.subject_type === 'account') {
                                    if (accounts[i].id === change.subject_id) {
                                      subject_name = accounts[i].name
                                    }
                                  }
                                  if (accounts[i].id === old_value) {
                                    old_value = accounts[i].name
                                  }
                                  if (accounts[i].id === new_value) {
                                    new_value = accounts[i].name
                                  }
                                }
                                if (change.subject_type === 'client') {
                                  for (let i in clients) {
                                    if (clients[i].id === change.subject_id) {
                                      subject_name = clients[i].name
                                    }
                                    if (clients[i].id === old_value) {
                                      old_value = clients[i].name
                                    }
                                    if (clients[i].id === new_value) {
                                      new_value = clients[i].name
                                    }
                                  }
                                }
                                field =
                                  String(subject_name) +
                                  ' / ' +
                                  String(change.field_name)

                                return (
                                  <tr
                                    key={
                                      String(change.subject_id) + String(index)
                                    }
                                    className="overflow-hidden hover:bg-blue-50"
                                    onClick={() =>
                                      navigateToChange(
                                        change.subject_type,
                                        change.subject_id
                                      )
                                    } // TODO
                                  >
                                    <td className={classes}>
                                      <Typography
                                        placeholder="."
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {author_name}
                                      </Typography>
                                    </td>
                                    <td className={classes}>
                                      <Typography
                                        placeholder="."
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {field}
                                      </Typography>
                                    </td>
                                    <td className={classes}>
                                      <Typography
                                        placeholder="."
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {old_value}
                                      </Typography>
                                    </td>
                                    <td className={classes}>
                                      <Typography
                                        placeholder="."
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {new_value}
                                      </Typography>
                                    </td>
                                    <td className={classes}>
                                      <Typography
                                        placeholder="."
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {change?.timestamp}
                                      </Typography>
                                    </td>
                                  </tr>
                                )
                              }
                            )}
                          </tbody>
                        ) : (
                          <>
                            <Typography placeholder="." className="p-5">
                              No changes were found.
                            </Typography>
                          </>
                        )}
                      </>
                    )}
                  </table>
                </Card>
              </div>
            </div>
            <div className="w-fit flex-grow p-5">
              <div>
                <div className="w-full">
                  <Accordion placeholder="." open={searchOpen}>
                    <AccordionHeader placeholder="." onClick={handleSearchOpen}>
                      Search By
                    </AccordionHeader>
                    <AccordionBody className="w-full">
                      <div className="flex max-h-64 w-full flex-col overflow-auto p-2">
                        <Radio
                          crossOrigin={false}
                          onClick={() => {
                            handleChangeSearchBy('author')
                          }}
                          label="Author"
                          name="searchBy"
                          defaultChecked
                        />
                        <Radio
                          crossOrigin={false}
                          onClick={() => {
                            handleChangeSearchBy('subject')
                          }}
                          label="Subject"
                          name="searchBy"
                        />
                        <Radio
                          crossOrigin={false}
                          onClick={() => {
                            handleChangeSearchBy('content')
                          }}
                          label="Content of Change"
                          name="searchBy"
                        />
                      </div>
                      <hr />
                    </AccordionBody>
                  </Accordion>
                </div>
              </div>
            </div>
          </>
        )}
      </Card>
  )
}

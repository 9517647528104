import { Typography } from '@material-tailwind/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useContext, useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { PermissionsContext } from '../../contexts'

export default function PermissionsOverivew() {
  const permissions = useContext(PermissionsContext)
  const [permissions_presets, setPermissions] = useState({})
  const [firstLoad, setFirstLoad] = useState(true)

  async function getPermissions() {
    await getDoc(doc(db, 'settings', 'permissions_file_templates')).then(
      (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          setPermissions(data)
        } else {
          alert(
            'There was an error loading permissions. Check internet contection and try again.'
          )
        }
      }
    )
  }
  const role_titles = [
    'Organizer',
    'VP',
    'Director',
    'Business Manager',
    'Marketing',
    'Admin',
    'Default',
  ]
  const permission_keys = [
    'add_accounts',
    'edit_accounts',
    'edit_my_clients_accounts',
    'edit_current_user_account',
    'add_clients',
    'edit_all_clients',
    'edit_assigned_clients',
    'view_tiers',
    'edit_payments',
    'view_payments',
    'view_my_client_payments',
    'edit_all_roles',
    'edit_my_client_roles',
    'edit_all_contacts',
    'edit_my_client_contacts',
    'view_settings',
    'edit_presets',
    'edit_marketing',
    'view_marketing',
  ]
  const permission_titles = [
    'Add Accounts',
    'Edit Accounts',
    "Edit My Clients' Accounts",
    'Edit Current User Account',
    'Add Clients',
    'Edit All Clients',
    'Edit Assigned Clients',
    'View Tiers',
    'Edit Payments',
    'View Payments',
    'View My Client Payments',
    'Edit All Roles',
    'Edit My Client Roles',
    'Edit All Contacts',
    'Edit My Client Contacts',
    'View Settings',
    'Edit Presets',
    'Edit Marketing',
    'View Marketing',
  ]
  // eslint-disable-next-line
  useEffect(() => {
    if (firstLoad) {
      getPermissions()
      setFirstLoad(false)
    }
  })
  return (
    <div>
      <div className="p-2">
        Your Permissions Role:
        {permissions.title ? (
          <div className="font-bold">{permissions.title}</div>
        ) : (
          <> Default</>
        )}
      </div>
      <div className="max-h-fit w-fit min-w-fit overflow-auto rounded-lg border border-gray-300 shadow-md">
        <table>
          <thead>
            <tr>
              <th
                key={'another_header_row_blank_space'}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-2"
              ></th>
              {role_titles.map((title, index) => {
                return (
                  <th
                    key={'another_header_row' + index}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                  >
                    <Typography
                      placeholder="."
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {title}
                    </Typography>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {permission_keys.map((permission, index) => {
              const title = permission_titles[index]
              const classes = 'border-b border-blue-gray-50 p-2 w-24 border-r'
              return (
                <tr key={'role_line' + index}>
                  <td
                    className={
                      'border-b border-blue-gray-50 p-2 w-60 flex justify-start border-r'
                    }
                    key={'role_column_title_num' + index}
                  >
                    <Typography
                      placeholder="."
                      variant="small"
                      color="blue-gray"
                      className="flex justify-center font-normal"
                    >
                      {title}
                    </Typography>
                  </td>
                  {role_titles.map((role, index_2) => {
                    return (
                      <td className={classes} key={'role_column' + index_2}>
                        <Typography
                          placeholder="."
                          variant="small"
                          color="blue-gray"
                          className="flex justify-center font-normal"
                        >
                          {permissions_presets[role] !== undefined ? (
                            <div>
                              {permissions_presets[role][permission] ? (
                                <CheckIcon className="h-5 w-5 stroke-green-400" />
                              ) : (
                                <XMarkIcon className="h-5 w-5 stroke-red-400" />
                              )}
                            </div>
                          ) : (
                            <XMarkIcon className="h-5 w-5" />
                          )}
                        </Typography>
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

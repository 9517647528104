import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import FullPageLoading from './pages/public/FullPageLoading'
import { AnimatePresence } from 'framer-motion'
import { db, app } from './firebase'
import { doc, getDoc } from 'firebase/firestore'
import NotFound from './pages/public/NotFound'
import App from './App'
import { StatusContext } from './contexts'
import { getPerformance } from 'firebase/performance'

export default function AuthAppWrapper() {
  // authentication and user vars.
  const auth = getAuth()
  const perf = getPerformance(app)
  const [user, setUser] = useState<User | undefined>()

  // status of the website (allows site to be taken down for maintance as needed.)
  const [status, setStatus] = useState('')
  async function getSiteStatus() {
    await getDoc(doc(db, 'settings', 'status')).then((doc) => {
      if (doc.exists()) {
        const data = doc.data()
        if (data.servicing) {
          setStatus('servicing')
        } else if (data.error) {
          setStatus('error')
        } else {
          setStatus('')
        }
        if (data.latest_version) {
          // setLatestVersion(data.latest_version) # TODO still in development.
        }
      }
    })
  }
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user)
      console.log('User Logged In successfully.')
    } else {
      console.log('User is not signed in.')
    }
  })
  const [readyToProceed, setReadyToProceed] = useState(false)

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      getSiteStatus()
      setFirstLoad(false)
      setTimeout(() => {
        setReadyToProceed(true)
      }, 1500)
    }
  })

  return (
    <div>
      {status === '' ? (
        <>
          {auth && readyToProceed ? (
            <App user={user} />
          ) : (
            <AnimatePresence>
              <div className="z-50">
                <FullPageLoading />
              </div>
            </AnimatePresence>
          )}
        </>
      ) : (
        <StatusContext.Provider value={status}>
          <NotFound />
        </StatusContext.Provider>
      )}
    </div>
  )
}

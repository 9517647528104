import { Typography } from "@material-tailwind/react";


export default function WeeklyReportingList()
{
  return <>
    <Typography placeholder={"."} variant="h3">
      Weekly Reporting
    </Typography>
    <>Placeholders</>
  </>
}
import {
  Button,
  Card,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Textarea,
  Typography,
} from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import { CurrentUserContext } from '../contexts'
import { account } from '../types/account'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { Navigate } from 'react-router'

export default function SubmitFeedback() {
  const [formComplete, setFormComplete] = useState(false)
  const [formContent, setFormContent] = useState({
    subject: '',
    description: '',
    return_address: '',
    submitted: new Date()
  })

  const [firstLoad, setFirstLoad] = useState(true)
  const account: account | undefined = useContext(CurrentUserContext)
  useEffect(() => {
    if (formContent.subject !== "" &&
      formContent.description !== "" &&
      formContent.return_address !== "") {
      setFormComplete(true)
    } else {
      setFormComplete(false)
    }

    if (firstLoad && account) {
      setFormContent({ ...formContent, return_address: account.email })
      setFirstLoad(false)
    }
  })
  const [redirectHome, setRedirectHome] = useState(false)
  async function submit() {
    await addDoc(collection(db, "feedback"), formContent).then(() => {
      alert("Issue Report was saved successfully!")
      setRedirectHome(true)
    }).catch(() =>
      alert("There was an issue submitting your ticket. Please reload the page and try again.")
    )
  }
  return (
    <Card placeholder="." className="flex h-full w-fit flex-row rounded-lg">
      {redirectHome && <Navigate to="/" replace />}
      <div className="flex flex-col h-full w-fit p-4 justify-around">
        <div className="w-fit flex flex-col">
          <Typography variant="h4" placeholder={'.'} className="w-full">
            Have Feedback?
          </Typography>
          <hr className=" border-2 border-blue-800"></hr>
        </div>
        <div className="p-4">
          Whether it is a feature request or feature that frustrates you,<br />
          fill out the submission form below and it will added to the list.<br />
          Feature requests may not be implemented, but every bit of feedback helps!
        </div>
        <div className="p-4">
          <div className='py-2'>
            <Input crossOrigin="false" label="Subject" value={formContent.subject} onChange={(e) => setFormContent({ ...formContent, subject: e.target.value })} />
          </div>
          <div className='py-2'>
            <Textarea label="Description" value={formContent.description} onChange={(e) => setFormContent({ ...formContent, description: e.target.value })} />
          </div>
          <div>
            <Input crossOrigin="false" label="Return Address" value={formContent.return_address} onChange={(e) => setFormContent({ ...formContent, description: e.target.value })} />
          </div>
          <div className='py-6'>
            {formComplete ? (
              <Button placeholder={'.'} color="green" onClick={submit} >
                Submit
              </Button>
            ) : (
              <Button placeholder={'.'} color="green" disabled>
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

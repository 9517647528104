export default function ensureChangeType(obj) {
  var change_template = {
    actionDate: 0,
    author_id: "",
    field_name: "",
    new_value: "",
    old_value: "",
    subject_id: "",
    subject_type: "",
    timestamp: ""
  }

  Object.keys(obj).forEach((key)=>{
    change_template[key] = obj[key]
  })
  return change_template
}
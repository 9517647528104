// React
import { Link, Navigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

// firebase
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { setDoc, doc } from 'firebase/firestore'
import { db, storage } from '../../firebase'

// components / css
import '../../App.css'
import Loading from '../../components/Loading'
import {
  Button,
  Card,
  Typography,
  Input,
  Checkbox,
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
  PermissionsContext,
  ReloadDataContext,
} from '../../contexts'
import { account } from '../../types/account'
import parseChange from '../../functions/parseChange'

export default function AddAccount() {
  // redirect and reload
  const [primaryKey, setKey] = useState('starting_key')
  const [redirect, setRedirect] = useState({
    path: '',
    sendIt: false,
  })
  const reRenderPage = () => {
    setKey(String(Math.round(Math.random() * 100000)))
  }

  // generic contexts.
  const accounts = useContext(AccountsContext)
  const clients = useContext(ClientsContext)
  const permissions = useContext(PermissionsContext)
  const currentUser = useContext(CurrentUserContext)

  // account form contents
  const [accountInfo, setAcctInfo] = useState<account>({
    name: '',
    phone: '',
    email: '',
    title: '',
    internal: false,
    avatar: '',
    id: '',
  })

  const [firstLoad, setFirstLoad] = useState(true)
  const reloadData = useContext(ReloadDataContext)
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
      reloadData()
      if (accountInfo.id === '') {
        // find account id that is unallocated in preparation for saving document.
        getNewID()
      }
    }
  })

  async function getNewID() {
    const new_id = await getId(250)
    setAcctInfo({ ...accountInfo, id: new_id })
  }
  function handleChangeName(new_value) {
    setAcctInfo({
      ...accountInfo,
      name: new_value.target.value,
    })
  }
  function handleChangePhone(new_value) {
    setAcctInfo({
      ...accountInfo,
      phone: new_value.target.value,
    })
  }
  function handleChangeCellPhone(new_value) {
    setAcctInfo({
      ...accountInfo,
      'Cell Phone': new_value.target.value,
    })
  }
  function handleChangeEmail(new_value) {
    setAcctInfo({
      ...accountInfo,
      email: new_value.target.value,
    })
  }
  function handleChangeTitle(new_value) {
    setAcctInfo({
      ...accountInfo,
      title: new_value.target.value,
    })
  }
  function toggleInternal() {
    setAcctInfo({
      ...accountInfo,
      internal: !accountInfo.internal,
    })
  }

  // account photo
  const [fileIsImg, setFileIsImg] = useState(false) // bool based on if profile pic is png.
  const [selectedFile, setSelectedFile] = useState<Blob>() // uploaded photo variable. (undefined until chosen.)
  const [customAvatar, setCustomAvatar] = useState('') // string link to selected file after its uploaded to gstorage.
  const [fileName, setFileName] = useState<string>('')
  const onFileChange = (event) => {
    // changing file selection (not uploading to gstorage yet).
    try {
      if (
        event.target.files[0].type === 'image/png' ||
        event.target.files[0].type === 'image/jpeg' ||
        event.target.files[0].type === 'image/JPG' ||
        event.target.files[0].type === 'image/webp'
      ) {
        // downsizing file
        const file = event.target.files[0]
        const blobURL = URL.createObjectURL(file)
        const MIME_TYPE = 'image/jpeg'
        const QUALITY = 1
        const img = new Image()
        img.src = blobURL
        img.onerror = function () {
          URL.revokeObjectURL(this.src)
          // Handle the failure properly
          console.log('Cannot load image')
        }
        img.onload = function () {
          URL.revokeObjectURL(blobURL)
          const [newWidth, newHeight] = calculateSize(img, 300, 300)
          const canvas = document.createElement('canvas')
          canvas.width = newWidth
          canvas.height = newHeight
          const ctx = canvas.getContext('2d')
          if (ctx !== null) {
            ctx.drawImage(img, 0, 0, newWidth, newHeight)
            canvas.toBlob(
              (blob) => {
                // Handle the compressed image. es. upload or save in local state
                // displayInfo('Original file', file);
                if (blob !== null) {
                  setSelectedFile(blob)
                }
              },
              MIME_TYPE,
              QUALITY
            )
          }
        }
        function calculateSize(img, maxWidth, maxHeight) {
          let width = img.width
          let height = img.height

          // calculate the width and height, constraining the proportions
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width)
              width = maxWidth
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height)
              height = maxHeight
            }
          }
          return [width, height]
        }
        setFileIsImg(true)
        setFileName(accountInfo.id)
      } else {
        setFileIsImg(false)
        alert('The file seems to be the wrong format. Please try again.')
      }
    } catch (err) {
      console.log(err)
    }
  }
  const onFileUpload = () => {
    if (selectedFile) {
      // uploading selected file to gstorage
      const formData = new FormData()
      formData.append('File', selectedFile)
      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: selectedFile.type,
      }

      // Upload file and metadata to the object 'images/mountains.jpg'

      const storageRef = ref(storage, 'account_profile_pictures/' + fileName)
      const uploadTask = uploadBytesResumable(
        storageRef,
        selectedFile,
        metadata
      )

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        (error) => {
          // A full list of error codes is available at
          // [Handle errors for Cloud Storage on Web | Cloud Storage for Firebase](https://firebase.google.com/docs/storage/web/handle-errors)
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break
            case 'storage/canceled':
              // User canceled the upload
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL)
            setCustomAvatar(downloadURL)
          })
        }
      )
    }
  }

  // submission process
  const [submiting, setSubmitting] = useState(false) // this is the modal controller
  const [submissionComplete, setSubComplete] = useState(false) // controls text and loading icon in modal
  async function beginSubmissionProcess() {
    setSubmitting(true)
    await saveAccount().then(() => {
      setSubComplete(true)
    })
  }

  // clear page info for failed uploads.
  function resetPageToDefaults() {
    // return values to default
    setFileIsImg(false)
    setSubmitting(false)
    setCustomAvatar('')
    setSubComplete(false)

    setAcctInfo({
      ...accountInfo,
      name: '',
      phone: '',
      email: '',
      title: '',
      company: '',
      avatar: '',
      id: '',
    })
  }

  // sets redirect be true for next refresh and sets the path for said redirect.
  function redirectToAccount(new_account_id) {
    setRedirect({
      path: '/view/account#' + new_account_id,
      sendIt: true,
    })
  }

  // save account info and redirect to new account's page on success
  // on failure, alert user and reset page.
  const [failedSignUp, setFailSignUp] = useState(false)
  async function saveAccount() {
    try {
      // setup final account details.
      var data = accountInfo
      data.avatar = customAvatar

      var valid_info = true // needs to actually check info.

      // set account if permissions are present.
      if (permissions.add_accounts && valid_info) {
        // submit change to change log.
        const blank_account_doc: account = {
          id: '000000',
          email: '',
          internal: false,
          name: '',
        }
        await parseChange(
          'account',
          data.id,
          blank_account_doc,
          data,
          currentUser
        )

        // set redirect path for next rerender.
        redirectToAccount(data.id)
        setFailSignUp(false)

        // write doc to firestore. (triggers dom refresh.)
        await setDoc(doc(db, 'accounts', data.id), data)
      } else {
        console.log('User does not have permission to add accounts.')
        alert(
          "Your account does not have permission to add accounts.\nIf you believe this is an issue please submit a bug request through the submission form in the header's dropdown."
        )
        resetPageToDefaults()
        setFailSignUp(true)
      }
    } catch (err) {
      console.log(err)
      alert('There was an error saving new account details.')
      resetPageToDefaults()
      setFailSignUp(true)
    }
  }

  // get a new account id that does not collide with any current users.
  async function getId(timeout) {
    // recursive function for finding id strings, timeout is set to original_timeout * (recusion_depth * 2) to ensure possible issues aren't catastrophic.
    var possible_id = String(Math.round(Math.random() * 1000000))
    var possible_id_taken = false
    // ensure possible id takes up 6 digits.
    if (Number(possible_id) < 100000) {
      possible_id = String(Number(possible_id) + 100000)
    }
    for (let i in accounts) {
      if (accounts[i].id === possible_id) {
        possible_id_taken = true
      }
    }
    for (let i in clients) {
      if (clients[i].id === possible_id) {
        possible_id_taken = true
      }
    }
    if (possible_id_taken) {
      return String(
        setTimeout(() => {
          getId(timeout * 2)
        }, timeout)
      )
    } else {
      return possible_id
    }
  }

  // close popup and reset state indicators to match.
  function closeModal() {
    setSubmitting(false)
    reRenderPage()
  }

  return (
    <Card
      placeholder="."
      key={primaryKey}
      className="flex h-screen flex-row rounded-sm p-10"
    >
      {redirect.sendIt ? <Navigate to={redirect.path} replace /> : <></>}
      <Dialog
        placeholder="."
        open={submiting}
        handler={setSubmitting}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader placeholder=".">Setting Up New Account...</DialogHeader>
        {failedSignUp === true ? (
          <>
            <DialogBody placeholder=".">
              {submissionComplete ? (
                <>
                  <strong>Submission was Unsuccessful. </strong>
                  <br></br> Either the email submitted is already taken, or
                  another issue occured. <br />
                  Please try again or contact support.
                </>
              ) : (
                <>
                  Creating New Account ID <Loading />
                </>
              )}
            </DialogBody>
            <DialogFooter placeholder=".">
              <div>
                <Button variant="text" placeholder="." onClick={closeModal}>
                  <span>Return to Create Account Page</span>
                </Button>
              </div>
            </DialogFooter>
          </>
        ) : (
          <Loading />
        )}
      </Dialog>
      <div className="w-1/2 p-2">
        <Typography placeholder="." variant="h4">
          General Information
        </Typography>
        <div className="w-1/2">
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Name"
              type="text"
              size="lg"
              className=""
              value={accountInfo.name}
              onChange={handleChangeName}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Phone"
              type="text"
              size="lg"
              className=""
              value={accountInfo.phone}
              onChange={handleChangePhone}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Cell Phone"
              type="text"
              size="lg"
              className=""
              value={accountInfo['Cell Phone']}
              onChange={handleChangeCellPhone}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Email"
              type="text"
              size="lg"
              className=""
              value={accountInfo.email}
              onChange={handleChangeEmail}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Title"
              type="text"
              size="lg"
              className=""
              value={accountInfo.title}
              onChange={handleChangeTitle}
            />
          </div>
          {permissions.edit_all_roles && ( // only admins should be able to add employees.
            <div className="p-4">
              <Checkbox
                crossOrigin={false}
                label="OSMG Employee?"
                onClick={toggleInternal}
              ></Checkbox>
            </div>
          )}
        </div>
        <div className="p-10">
          <Button placeholder="." onClick={beginSubmissionProcess}>
            Submit
          </Button>
        </div>
      </div>
      <div className="h-full w-1/2 p-2">
        <Typography placeholder="." variant="h4">
          Profile Photo
        </Typography>
        <div className="h-full w-full justify-center align-middle">
          {customAvatar !== '' ? (
            <>
              <Avatar
                placeholder="."
                className="h-40 w-40 border-4"
                src={customAvatar}
              />
            </>
          ) : (
            <>
              <Avatar placeholder="." className="h-40 w-40 border-4" src="" />
            </>
          )}
          <div className="w-1/2 py-6">
            <Input
              crossOrigin={false}
              type="file"
              onChange={onFileChange}
              label="Choose File"
            />
            <div className="py-6">
              {fileIsImg ? (
                <>
                  <Button
                    placeholder="."
                    onClick={() => {
                      onFileUpload()
                    }}
                  >
                    Upload
                  </Button>
                </>
              ) : (
                <></>
              )}

              {customAvatar !== '' ? (
                <>
                  <Button
                    placeholder="."
                    variant="text"
                    onClick={() => {
                      setCustomAvatar('')
                    }}
                  >
                    Remove Custom Image
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

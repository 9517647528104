// React
import { Link, Navigate } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'

// firebase
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { setDoc, doc, getDoc } from 'firebase/firestore'
import { db, storage } from '../../firebase'

// components / css
import '../../App.css'
import Loading from '../../components/Loading'
import {
  Button,
  Card,
  Typography,
  Input,
  Avatar,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Chip,
  Menu,
  MenuList,
  MenuHandler,
  MenuItem,
} from '@material-tailwind/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import {
  AccountsContext,
  ClientsContext,
  ReloadDataContext,
} from '../../contexts'
import { client } from '../../types/client'
import { account } from '../../types/account'
import clientTemplate from '../../functions/ensureType/templateClient'

export default function AddClient() {
  // general page
  const [primaryKey, setKey] = useState('starting_key')
  const [firstLoad, setFirstLoad] = useState(true)
  const [redirect, setRedirect] = useState({
    path: '',
    sendIt: false,
  })
  const reload = () => {
    setKey(String(Math.round(Math.random() * 100000)))
  }

  // form contents
  const [clientInfo, setClientInfo] = useState<client>(clientTemplate())
  const [aliases, setAliases] = useState<string[]>([])
  const [aliasInput, setAliasInput] = useState<string>('')
  const [brands, setBrands] = useState<string[]>([])
  const [brandInput, setBrandInput] = useState<string>('')
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  function handleChangeStartDate(new_value) {
    setStartDate(new_value.target.value)
    var string_date = new_value.target.value
    string_date.toString()
    // converting from YYYY-MM-DD to MM/DD/YYYY
    const date_array = string_date.split('-')
    string_date = date_array[1] + '/' + date_array[2] + '/' + date_array[0]

    setClientInfo({
      ...clientInfo,
      start_date: string_date,
    })
  }
  function handleChangeEndDate(new_value) {
    setEndDate(new_value.target.value)
    var string_date = new_value.target.value
    string_date.toString()
    // converting from YYYY-MM-DD to MM/DD/YYYY
    const date_array = string_date.split('-')
    string_date = date_array[1] + '/' + date_array[2] + '/' + date_array[0]

    setClientInfo({
      ...clientInfo,
      end_date: string_date,
    })
  }
  function handleChangeName(new_value) {
    setClientInfo({
      ...clientInfo,
      name: new_value.target.value,
    })
  }
  function handleChangeId(new_value) {
    setClientInfo({
      ...clientInfo,
      file_id: new_value.target.value,
    })
  }
  function handleChangeComFile(new_value) {
    setClientInfo({
      ...clientInfo,
      commission_file: new_value.target.value,
    })
  }
  function handleChangeTier(new_value) {
    setClientInfo({
      ...clientInfo,
      tier: new_value,
    })
  }
  function handleChangeAlias(new_value) {
    setAliasInput(new_value.target.value)
  }
  function handleAddAlias() {
    var new_aliases: string[] = aliases
    new_aliases.push(String(aliasInput))
    setAliases(new_aliases)
    setClientInfo({
      ...clientInfo,
      aliases: new_aliases,
    })
    setAliasInput('')
  }
  function clearAliases() {
    setAliases([])
    setClientInfo({
      ...clientInfo,
      aliases: [],
    })
  }
  function handleChangeBrand(new_value) {
    setBrandInput(new_value.target.value)
  }
  function handleAddBrand() {
    var new_brands = brands
    new_brands.push(String(brandInput))
    setBrands(new_brands)
    setBrandInput('')
    setClientInfo({
      ...clientInfo,
      brands: new_brands,
    })
  }
  function clearBrands() {
    setBrands([])
    setClientInfo({
      ...clientInfo,
      brands: [],
    })
  }
  
  function handleChangeGln(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        gln: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        gln: '',
      })
    }
  }
  function handleChangeKnum(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        k_num: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        k_num: '',
      })
    }
  }
  function handleChangeDuns(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        duns: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        duns: '',
      })
    }
  }
  function handleChangeNumMeetings(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        num_meetings: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        num_meetings: '',
      })
    }
  }
  function handleChangeNumCommodities(new_value) {
    if (new_value) {
      setClientInfo({
        ...clientInfo,
        num_comms: new_value.target.value,
      })
    } else {
      setClientInfo({
        ...clientInfo,
        num_comms: '',
      })
    }
  }


  // client logo
  const [fileIsImg, setFileIsImg] = useState(false) // bool based on if logo is png or jpeg.
  const [selectedFile, setSelectedFile] = useState<Blob>() // uploaded photo variable. (undefined until chosen.)
  const [fileName, setFileName] = useState<string>('')
  const [cleintLogo, setClientLogo] = useState('') // string link to selected file after its uploaded to gstorage.
  const onFileChange = (event) => {
    // changing file selection (not uploading to gstorage yet).
    try {
      setSelectedFile(event.target.files[0])
      setFileName(event.target.files[0].name)
      const img_type = event.target.files[0].type
      console.log(img_type)
      if (
        img_type === 'image/png' ||
        img_type === 'image/jpeg' ||
        event.target.files[0].type === 'image/svg+xml' ||
        event.target.files[0].type === 'image/webp' ||
        event.target.files[0].type === 'image/avif'
      ) {
        setFileIsImg(true)
      } else {
        setFileIsImg(false)
        alert('The file seems to be the wrong format. Please try again.')
      }
    } catch (err) {
      console.log(err)
    }
  }
  const onFileUpload = () => {
    if (selectedFile !== undefined) {
      // uploading selected file to gstorage
      const formData = new FormData()
      formData.append('File', selectedFile)
      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: selectedFile.type,
      }

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, 'images/' + fileName)
      const uploadTask = uploadBytesResumable(
        storageRef,
        selectedFile,
        metadata
      )

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused')
              break
            case 'running':
              console.log('Upload is running')
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        (error) => {
          alert('An Error Occured when uploading the image. Please try again.')
          // A full list of error codes is available at
          // [Handle errors for Cloud Storage on Web | Cloud Storage for Firebase](https://firebase.google.com/docs/storage/web/handle-errors)
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break
            case 'storage/canceled':
              // User canceled the upload
              break
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
            default:
              console.log('Error Occured.')
              break
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL)
            setClientLogo(downloadURL)
          })
        }
      )
    } else {
      alert('There was an issue uploading the file. Please Try Again.')
    }
  }

  // tiers
  const [tierOptions, setTierOptions] = useState([])
  async function getTiers() {
    const docSnap = await getDoc(doc(db, 'settings', 'tiers'))
    if (docSnap.exists()) {
      var data = docSnap.data()
      setTierOptions(data.options)
      console.log(data.options)
    }
  }

  // used to check for existing ids.
  const clients: client[] = useContext(ClientsContext)
  const accounts: account[] = useContext(AccountsContext)

  // submission process
  const [id, setID] = useState('') // set in SaveClient>getId and is needed for post save redirect.
  const [submiting, setSubmitting] = useState(false) // this is the modal controller
  const [submissionComplete, setSubComplete] = useState(false) // controls text and loading icon in modal
  function beginSubmissionProcess() {
    setSubmitting(true)
    saveClient().then(() => {
      setSubComplete(true)
    })
  }
  function endSubmissionProccess() {
    // return values to default
    setFileIsImg(false)
    setClientLogo('')
    setSubComplete(false)
    setClientInfo(clientTemplate())
  }
  function redirectToClient(id) {
    setRedirect({
      path: '/view/client#' + id,
      sendIt: true,
    })
  }
  async function saveClient() {
    setSubmitting(true)
    var data = clientInfo
    data.logo = cleintLogo
    data.id = await getId(500)
    setID(data.id)
    endSubmissionProccess()
    redirectToClient(data.id)
    await setDoc(doc(db, 'clients', data.id), data)
  }
  async function getId(timeout) {
    // recursive function for finding id strings, timeout is set to original_timeout * (recusion_depth * 2) to ensure possible issues aren't catastrophic.
    const possible_id = String(Math.round(Math.random() * 1000000))
    var possible_id_taken = false
    for (let i in clients) {
      if (clients[i].id === possible_id) {
        possible_id_taken = true
      }
    }
    for (let i in accounts) {
      if (accounts[i].id === possible_id) {
        possible_id_taken = true
      }
    }
    if (possible_id_taken) {
      return String(
        setTimeout(() => {
          getId(timeout * 2)
        }, timeout)
      )
    } else {
      return possible_id
    }
  }
  function closeModal() {
    setSubmitting(false)
    reload()
    setEndDate('')
    setStartDate('')
    setClientInfo(clientTemplate())
    clearAliases()
    clearBrands()
  }
  const reloadData = useContext(ReloadDataContext)
  useEffect(() => {
    if (firstLoad) {
      getTiers()
      reloadData()
      setFirstLoad(false)
    }
  }, [firstLoad, setFirstLoad, reloadData, getTiers])

  return (
    <Card
      placeholder="."
      key={primaryKey}
      className="flex h-fit flex-row rounded-sm p-10"
    >
      {redirect.sendIt ? <Navigate to={redirect.path} replace /> : <></>}
      <Dialog
        placeholder="."
        open={submiting}
        handler={setSubmitting}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader placeholder=".">Setting Up New Client...</DialogHeader>
        <DialogBody placeholder=".">
          {submissionComplete ? (
            <>Submission was successful!</>
          ) : (
            <>
              Building document. <Loading />{' '}
            </>
          )}
        </DialogBody>
        <DialogFooter placeholder=".">
          {submissionComplete ? (
            <div>
              <Button variant="text" placeholder="." onClick={closeModal}>
                <span>Return to Create Client Page</span>
              </Button>
              <Link to={'/view/client#' + id}>
                <Button
                  placeholder="."
                  variant="gradient"
                  color="green"
                  onClick={redirectToClient}
                >
                  <span>View Client</span>
                </Button>
              </Link>
            </div>
          ) : (
            <></>
          )}
        </DialogFooter>
      </Dialog>
      <div className="w-1/2 p-2">
        <Typography variant="h4" placeholder=".">
          General Information
        </Typography>
        <div className="w-1/2">
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Name"
              type="text"
              size="lg"
              className=""
              value={clientInfo.name}
              onChange={handleChangeName}
            />
          </div>

          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="ID"
              type="text"
              size="lg"
              className=""
              value={clientInfo.file_id}
              onChange={handleChangeId}
            />
          </div>
          <div className="p-4">
            <Input
              crossOrigin={false}
              variant="standard"
              label="Commission File"
              type="text"
              size="lg"
              className=""
              value={clientInfo.commission_file}
              onChange={handleChangeComFile}
            />
          </div>
          <div>
            <div className="p-4">
              <Input
                crossOrigin={false}
                variant="standard"
                label="Start Date"
                type="date"
                size="lg"
                className=""
                value={startDate}
                onChange={handleChangeStartDate}
              />
            </div>
            <div className="p-4">
              <Input
                crossOrigin={false}
                variant="standard"
                label="End Date"
                type="date"
                size="lg"
                className=""
                value={endDate}
                onChange={handleChangeEndDate}
              />
            </div>
          </div>
          <div className="flex flex-col p-4 pt-8">
            <div className="flex h-fit w-fit flex-row">
              <Typography placeholder="." variant="h5" className=" text-md">
                Aliases
              </Typography>
              {aliases.length > 0 && (
                <div>
                  <div className="flex-fit px-4">
                    <Button
                      placeholder="."
                      variant="text"
                      color="red"
                      className="p-1 px-2"
                      onClick={clearAliases}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <hr className=" border-1 border-black"></hr>
            <div className="flex flex-row flex-wrap">
              {aliases[0] !== undefined ? (
                <div>
                  {aliases?.map((value, index) => {
                    return (
                      <div className="p-2">
                        <Chip
                          key={'alias_' + index}
                          variant="outlined"
                          value={value}
                          className="w-fit"
                        />
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="flex h-10 flex-row justify-between py-2 pt-4 align-bottom">
              <Input
                crossOrigin={false}
                variant="standard"
                label="New Alias"
                type="text"
                size="lg"
                value={aliasInput}
                onChange={handleChangeAlias}
              ></Input>
              <div className="h-full w-full pt-4 align-bottom">
                <IconButton
                  placeholder="."
                  variant="text"
                  className="h-8 w-8 min-w-max"
                  onClick={handleAddAlias}
                >
                  <PlusIcon className="h-8 w-8" />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4">
            <div className="flex h-fit w-fit flex-row pt-4">
              <Typography placeholder="." variant="h5" className=" text-md">
                Brands
              </Typography>
              {brands.length > 0 && (
                <div>
                  <div className="flex-fit px-4">
                    <Button
                      placeholder="."
                      variant="text"
                      color="red"
                      className="p-1 px-2"
                      onClick={clearBrands}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <hr className=" border-1 border-black "></hr>
            <div className="flex flex-row flex-wrap">
              {brands[0] !== undefined ? (
                <>
                  {brands?.map((value, index) => {
                    return (
                      <div className="p-2">
                        <Chip
                          key={'brand_' + index}
                          variant="outlined"
                          value={value}
                          className="w-fit"
                        />
                      </div>
                    )
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="flex h-10 flex-row justify-between py-2 pt-4 align-bottom">
              <Input
                crossOrigin={false}
                variant="standard"
                label="New Brand"
                type="text"
                size="lg"
                value={brandInput}
                onChange={handleChangeBrand}
              ></Input>
              <div className="h-full w-full pt-4 align-bottom">
                <IconButton
                  placeholder="."
                  variant="text"
                  className="h-8 w-8 min-w-max"
                  onClick={handleAddBrand}
                >
                  <PlusIcon className="h-8 w-8" />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="flex flex-col p-4 pt-8"></div>
        </div>
        <div className="p-10">
          <Button placeholder="." onClick={beginSubmissionProcess}>
            Submit
          </Button>
        </div>
      </div>
      <div className="h-full w-1/2 p-2">
        <Typography placeholder="." variant="h4">
          Logo
        </Typography>
        <div className="h-full w-full justify-center align-middle">
          {cleintLogo !== '' ? (
            <>
              <Avatar
                placeholder="."
                className="h-40 w-40 border-4"
                src={cleintLogo}
              />
            </>
          ) : (
            <>
              <Avatar placeholder="." className="h-40 w-40 border-4" src="" />
            </>
          )}
          <div className="w-1/2 py-6">
            <Input
              crossOrigin={false}
              type="file"
              onChange={onFileChange}
              label="Choose File"
            />
            <div className="py-6">
              {fileIsImg ? (
                <>
                  <Button
                    placeholder="."
                    onClick={() => {
                      onFileUpload()
                    }}
                  >
                    Upload
                  </Button>
                </>
              ) : (
                <></>
              )}

              {cleintLogo !== '' ? (
                <>
                  <Button
                    placeholder="."
                    variant="text"
                    onClick={() => {
                      setClientLogo('')
                    }}
                  >
                    Remove Custom Image
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="p-4">
            <div className="flex h-fit w-fit flex-row pt-4">
              <Typography placeholder="." variant="h5" className=" text-md">
                Tier
              </Typography>
            </div>
            <hr className=" border-1 border-black "></hr>
            <div className="p-2">
              <Menu>
                <MenuHandler>
                  <Button placeholder="." variant="outlined">
                    {clientInfo.tier !== '' ? (
                      <>{clientInfo.tier}</>
                    ) : (
                      <>Choose Tier</>
                    )}
                  </Button>
                </MenuHandler>
                <MenuList placeholder=".">
                  {tierOptions.map((option, index) => {
                    return (
                      <MenuItem
                        placeholder="."
                        key={index + '_tier_option'}
                        onClick={() => {
                          handleChangeTier(option)
                        }}
                      >
                        {option}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </Menu>
            </div>
            
            <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="GLN"
                    type="text"
                    size="lg"
                    className=""
                    value={clientInfo.gln}
                    onChange={handleChangeGln}
                  />
                </div>
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="K Number"
                    type="text"
                    size="lg"
                    className=""
                    value={clientInfo.k_num}
                    onChange={handleChangeKnum}
                  />
                </div>
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="Duns"
                    type="text"
                    size="lg"
                    className=""
                    value={clientInfo.duns}
                    onChange={handleChangeDuns}
                  />
                </div>
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="Seasonal / Sales Meetings"
                    type="text"
                    size="lg"
                    className=""
                    value={clientInfo.num_meetings}
                    onChange={handleChangeNumMeetings}
                  />
                </div>
                <div className="p-4">
                  <Input
                    crossOrigin={false}
                    variant="standard"
                    label="Number of Commodities in Kroger"
                    type="text"
                    size="lg"
                    className=""
                    value={clientInfo.num_comms}
                    onChange={handleChangeNumCommodities}
                  />
                </div>
          </div>
          {/* Child field is being shadow cut because it was a terrible idea and not worth building. It is not being deleted incase certain people insist on it coming back. (Ideally nobody notices.)
            <div className="p-4">
              <div className="flex h-fit w-full flex-row justify-between pt-4">
                <Typography placeholder="." variant="h5" className=" text-md">
                  Child
                </Typography>
                {childSelection !== "" && (
                  <Button
                    placeholder="."
                    className="p-1"
                    variant="text"
                    onClick={clearChildSelection}
                  >
                    clear selection
                  </Button>
                )}
              </div>
              <hr className=" border-1 border-black "></hr>
              {childSelection !== "" && (
                <Typography
                  placeholder="."
                  variant="small"
                  className=" text-md"
                >
                  {childSelection}
                </Typography>
              )}
              <div className="p-2">
                <div className="w-96 p-2 pl-4">
                  <Input
                    crossOrigin={false}
                    label="Search"
                    value={childQuery}
                    onChange={(e) => {
                      setChildQuery(e.target.value);
                    }}
                    onSelect={handleOpenChildSearch}
                    icon={<MagnifyingGlassIcon />}
                  />
                </div>
                <div className="pl-4">
                  {childSearchOpen && (
                    <ul className="flex h-48 w-96 flex-col overflow-auto rounded-md border-2">
                      {firstLoad === false ? (
                        <>
                          {searchChild(clients).map((client, index) => {
                            return (
                              <li
                                className="px-2 py-1"
                                key={index + "_cleintButton"}
                              >
                                <Button
                                  placeholder="."
                                  variant="text"
                                  className="rounded-none p-1"
                                  onClick={() => {
                                    selectChildClient(client);
                                  }}
                                >
                                  {client?.name}
                                </Button>
                              </li>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </ul>
                  )}
                </div>
              </div>
                </div>*/}
        </div>
      </div>
    </Card>
  )
}

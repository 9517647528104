import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Input,
  Radio,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline'
import { useContext, useEffect, useState } from 'react'
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { AccountsContext } from '../../contexts'
import { account } from '../../types/account'
import Loading from '../../components/Loading'
import createAccount from '../../functions/createAccount'

export default function PermissionsAccessManagement() {
  const accounts = useContext(AccountsContext)
  const [permissions_presets, setPermissions] = useState({})
  const [allPermissions, setAllPermissions] = useState({})
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [selectedPerRole, setSelectedPerRole] = useState('')
  const [query, setQuery] = useState('')
  const [choosingPermissions, setChoosingPermissions] = useState(false)
  const [acctKey, setAcctKey] = useState('')
  const [usersWithPerm, setUsersWithPermissions] = useState<account[]>([])
  const [accountSearchOpen, setAccountSearchOpen] = useState(false)
  const [addAccount, setAddAccount] = useState(false)
  const [accountQuery, setAccountQuery] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [usersWithoutPermissions, setUsersWithoutPermissions] = useState<
    account[]
  >([])

  const TABLE_HEAD = ['Name', 'Phone #', 'Email', 'Title', '']
  const role_titles = [
    'Organizer',
    'VP',
    'Director',
    'Business Manager',
    'Marketing',
    'Admin',
    'Default',
  ]

  async function getAccountsBasedOnPermissions() {
    var accounts_from_permissions: account[] = []
    var ids_from_permissions: string[] = []
    const user_conversions = await getDocs(collection(db, 'users'))
    user_conversions.forEach((doc) => {
      if (doc.exists()) {
        const data = doc.data()
        if (data.id && typeof data.id === 'string') {
          accounts.forEach((account) => {
            if (account.id === data.id) {
              accounts_from_permissions.push(account)
            }
          })
          ids_from_permissions.push(data.id)
        }
      }
    })
    setUsersWithPermissions(accounts_from_permissions)
    var accounts_without_permissions: account[] = []
    var ids_without_permissions: string[] = []
    accounts.forEach((account) => {
      if (ids_from_permissions.includes(account.id) === false) {
        ids_without_permissions.push(account.id)
        accounts_without_permissions.push(account)
      }
    })
    setUsersWithoutPermissions(accounts_without_permissions)
  }
  async function getPermissions() {
    await getDoc(doc(db, 'settings', 'permissions_file_templates')).then(
      (doc) => {
        if (doc.exists()) {
          const data = doc.data()
          setPermissions(data)
        } else {
          alert(
            'There was an error loading permissions. Check internet contection and try again.'
          )
        }
      }
    )
  }
  async function getRoleAssignments() {
    await getDocs(collection(db, 'permissions')).then((querySnapshot) => {
      var temp = {}
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        if (data !== undefined) {
          temp[doc.id] = data
        }
      })
      setAllPermissions(temp)
    })
  }
  function exitWithoutSaving() {
    setSelectedAccounts([])
    setSelectedPerRole('')
    setChoosingPermissions(false)
    handleDialog()
  }
  function handleDialog() {
    setDialogOpen(!dialogOpen)
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }
  const reloadAccountsTable = () => {
    setAcctKey(String(Math.round(Math.random() * 100000)))
  }
  function toggleAccountSelection(id: string) {
    var new_selectedAccounts_object: string[] = selectedAccounts
    if (!selectedAccounts.includes(id)) {
      new_selectedAccounts_object.push(id)
      setSelectedAccounts(new_selectedAccounts_object)
      reloadAccountsTable()
    } else {
      var current_accounts: string[] = selectedAccounts
      var final_accounts: string[] = []
      for (let index in current_accounts) {
        if (current_accounts[index] !== id) {
          final_accounts.push(current_accounts[index])
        }
      }
      new_selectedAccounts_object = final_accounts
      setSelectedAccounts(new_selectedAccounts_object)
    }
  }
  function search() {
    var array_of_accounts: account[] = []
    for (let key in accounts) {
      if (accounts[key].UID !== undefined) {
        array_of_accounts.push(accounts[key])
      }
    }
    return array_of_accounts.filter((account) =>
      account.name.toString().toLowerCase().includes(query.toLowerCase())
    )
  }
  async function saveAccountsForNewPermissions() {
    for (let i in selectedAccounts) {
      try {
        var UID
        const permissions_title = selectedPerRole
        var permissions_content = permissions_presets[selectedPerRole]
        permissions_content['title'] = permissions_title
        for (let j in accounts) {
          if (accounts[j].id === selectedAccounts[i]) {
            UID = accounts[j].UID
            console.log('Account matched with UID')
            await setDoc(doc(db, 'permissions', UID), permissions_content).then(
              () => {
                setFirstLoad(true)
              }
            )
          }
        }
      } catch (err) {
        console.log(err)
        alert(
          'Error saving new permissions for Account ID: ' +
            selectedAccounts[i] +
            '\nProceeding with next account(s).'
        )
      }
    }
    exitWithoutSaving()
  }
  async function addSelectedAccountToPermissions(account_id) {
    if (account_id !== undefined && account_id !== '') {
      await getDoc(doc(db, 'accounts', account_id)).then((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data()
          if (data.UID !== undefined && data.UID !== '') {
            getDoc(doc(db, 'permissions', data.UID)).then((docSnap2) => {
              if (docSnap2.exists()) {
                alert(
                  'Account already has permissions file. \nPlease add them using the Plus Icon.'
                )
              } else {
                setDoc(doc(db, 'permissions', data.UID), {
                  title: 'Default',
                })
                  .then(() => {
                    alert('Adding Permissions was successful!')
                  })
                  .catch((err) => {
                    alert(
                      'There was an error saving the new permissions doc, please try again!'
                    )
                  })
              }
            })
          } else {
            const add_account_cred = window.confirm(
              'Are you sure you would like to give this user access?'
            )
            if (add_account_cred) {
              createAccount({ email: data.email })
                .catch((err) => {
                  console.log(err)
                  alert('Adding User Failed. Console logged the issue.')
                })
            }
          }
        } else {
          alert(
            'Could not find the selected account. Check your internet connection.'
          )
        }
      })
    } else {
      alert(
        'An Error Occurred when adding account. Please make sure you selected an option and try again.'
      )
    }
    setAccountQuery('')
    setAccountSearchOpen(false)
    setAddAccount(false)
  }
  function handleAccountSearch() {
    setAccountSearchOpen(!accountSearchOpen)
  }
  function searchAccount(accounts: account[]) {
    return accounts.filter((account) => {
      return account.name.toLowerCase().includes(accountQuery.toLowerCase())
    })
  }
  function permissionsDisplayFilter(permission_docs_ids: string[]) {
    var permission_levels_by_UID = {
      Organizer: [],
      VP: [],
      Director: [],
      'Business Manager': [],
      Marketing: [],
      Admin: [],
      Default: [],
    }
    var uids_without_title: string[] = []
    for (let i = 0; i < permission_docs_ids.length; i++) {
      try {
        permission_levels_by_UID[
          allPermissions[permission_docs_ids[i]].title
        ].push(permission_docs_ids[i])
      } catch (err) {
        uids_without_title.push(permission_docs_ids[i])
      }
    }

    var final_array: string[] = []
    final_array.push(...permission_levels_by_UID['Organizer'])
    final_array.push(...permission_levels_by_UID['VP'])
    final_array.push(...permission_levels_by_UID['Director'])
    final_array.push(...permission_levels_by_UID['Business Manager'])
    final_array.push(...permission_levels_by_UID['Marketing'])
    final_array.push(...permission_levels_by_UID['Admin'])
    final_array.push(...permission_levels_by_UID['Default'])

    return final_array
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (firstLoad) {
      getPermissions()
      getRoleAssignments()
      getAccountsBasedOnPermissions()
      setFirstLoad(false)
    }
  })
  return (
    <div>
      <div className="flex flex-row">
        <div className="w-fit h-fit p-2">
          <div className="flex w-full justify-between pt-8">
            <Typography variant="h5" placeholder="." className="">
              Active Accounts
            </Typography>
            <Button
              placeholder={'.'}
              className="p-2"
              variant="text"
              onClick={handleDialog}
            >
              <PlusCircleIcon className="h-8 w-8" />
            </Button>
          </div>
          <div className="w-full h-96 overflow-auto rounded-lg border border-gray-300 shadow-md">
            <table className="table-auto text-left">
              <thead>
                <tr>
                  <th
                    key={'header_row_name'}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                  >
                    <Typography
                      placeholder="."
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Name
                    </Typography>
                  </th>
                  <th
                    key={'header_row_permissions'}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                  >
                    <Typography
                      placeholder="."
                      variant="small"
                      color="blue-gray"
                      className="px-2 font-normal leading-none opacity-70"
                    >
                      Permissions
                    </Typography>
                  </th>
                  <th
                    key={'header_row_edit'}
                    className="w-20 border-b border-blue-gray-100 bg-blue-gray-50"
                  ></th>
                </tr>
              </thead>
              <tbody className="overflow-auto">
                {permissionsDisplayFilter(Object.keys(allPermissions)).map(
                  (UID, index) => {
                    var account: account = {
                      id: '000000',
                      email: 'unknown@gmail.com',
                      internal: false,
                      name: 'Could not Find Account',
                    }
                    var display_account = false
                    for (let i in accounts) {
                      if (
                        accounts[i].UID !== undefined &&
                        accounts[i].UID === UID
                      ) {
                        account = accounts[i]
                        display_account = true
                      }
                    }
                    if (display_account) {
                      const classes =
                        'border-b border-blue-gray-50 border-r pl-2'
                      return (
                        <tr>
                          <td className={classes + ' w-60'}>{account.name}</td>
                          <td className={classes}>
                            {allPermissions[UID].title !== undefined ? (
                              <>{allPermissions[UID].title}</>
                            ) : (
                              <>Default</>
                            )}
                          </td>
                          <td className={classes + 'flex justify-center'}>
                            <Button
                              placeholder={'.'}
                              className="w-full p-2"
                              variant="text"
                              onClick={() => {
                                setSelectedAccounts([account.id])
                                setChoosingPermissions(true)
                                handleDialog()
                              }}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      )
                    } else {
                      return <></>
                    }
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-fit h-fit p-2">
          <div className="flex w-full justify-between pt-8">
            <Typography variant="h5" placeholder="." className="pr-2">
              Issue Credentials
            </Typography>
            <Tooltip
              content={
                <div>
                  Creating an Account does not immediately grant a login
                  credential.
                  <br />
                  (For security reasons.)
                  <br />
                  After creating an account you must issue that account login
                  credentials before they can login and before you can grant
                  them elevated priviledges.
                </div>
              }
            >
              <IconButton variant="text" placeholder={'.'}>
                <InformationCircleIcon className="h-8 w-8" />
              </IconButton>
            </Tooltip>
          </div>
          <div className="">
            {!addAccount ? (
              <div>
                <Button
                  placeholder={'.'}
                  variant="outlined"
                  className="p-2"
                  color="green"
                  onClick={() => {
                    setAddAccount(true)
                    setAccountSearchOpen(true)
                  }}
                >
                  Add Account
                </Button>
              </div>
            ) : (
              <div className="p-2">
                <div className="w-96 py-2">
                  <Input
                    crossOrigin={false}
                    label="Search"
                    value={accountQuery}
                    onChange={(e) => {
                      setAccountQuery(e.target.value)
                    }}
                    icon={<MagnifyingGlassIcon />}
                  />
                </div>
                <div className="w-96 py-2">
                  {accountSearchOpen ? (
                    <ul className="flex h-48 w-full flex-col overflow-auto rounded-md border-2">
                      {firstLoad === false ? (
                        <>
                          {searchAccount(usersWithoutPermissions).map(
                            (account: account, index) => {
                              if (account.internal) {
                                return (
                                  <li
                                    className="px-2 py-1"
                                    key={index + '_cleintButton'}
                                  >
                                    <Button
                                      placeholder="."
                                      variant="text"
                                      className="rounded-none p-1"
                                      onClick={() => {
                                        addSelectedAccountToPermissions(
                                          account.id
                                        )
                                        handleAccountSearch()
                                      }}
                                    >
                                      {account?.name}
                                    </Button>
                                  </li>
                                )
                              } else {
                                return (
                                  <div key={index + '_clientButtonEmpty'}></div>
                                )
                              }
                            }
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </ul>
                  ) : (
                    <Loading />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        placeholder="."
        open={dialogOpen}
        handler={handleDialog}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xl"
        className="max-h-fit"
      >
        <DialogHeader placeholder=".">
          <div className="flex w-full justify-between p-0">
            {choosingPermissions ? (
              <div>Select Permissions</div>
            ) : (
              <div>Select Accounts</div>
            )}
            <div className="p-4 text-sm text-gray-600">
              Accounts not listed below have not been setup with UIDs in the
              system.
            </div>
          </div>
        </DialogHeader>
        <DialogBody placeholder="." className="h-96 overflow-auto">
          {!choosingPermissions ? (
            <div>
              <div className="w-50 flex flex-row pb-5">
                <Input
                  crossOrigin={false}
                  label="Search"
                  value={query}
                  onChange={handleQuery}
                  icon={<MagnifyingGlassIcon />}
                />
              </div>
              <table
                className="w-full table-auto overflow-auto border-2 text-left"
                key={acctKey}
              >
                <thead>
                  <tr key={'header_row'}>
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={head + index}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          placeholder="."
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                {accounts !== undefined ? (
                  <tbody>
                    {search().map((account, index) => {
                      var classes = ''
                      if (selectedAccounts.includes(account.id)) {
                        classes = 'bg-blue-100'
                      } else {
                        classes = ''
                      }
                      return (
                        <tr
                          key={account.name + String(index)}
                          className={classes}
                        >
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account?.name}
                            </Typography>
                          </td>
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account.phone}
                            </Typography>
                          </td>
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account.email}
                            </Typography>
                          </td>
                          <td className="p-4">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {account.title}
                            </Typography>
                          </td>
                          <td className={` bg-blue-gray-50/50`}>
                            <div className="flex w-full justify-center pl-2">
                              <Button
                                placeholder="."
                                variant="text"
                                color="blue"
                                onClick={() => {
                                  toggleAccountSelection(account.id)
                                }}
                              >
                                Select
                              </Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                ) : (
                  <>
                    <div className="p-3">
                      <Loading />
                    </div>
                  </>
                )}
              </table>
            </div>
          ) : (
            <div className="flex flex-col">
              {role_titles.map((key, index) => {
                return (
                  <Radio
                    name="Permissions"
                    key={key + '_' + index}
                    label={key}
                    className="flex items-center gap-2"
                    onClick={() => setSelectedPerRole(key)}
                    crossOrigin={'false'}
                  />
                )
              })}
            </div>
          )}
        </DialogBody>
        <DialogFooter placeholder=".">
          <>
            <Button placeholder="." variant="text" onClick={exitWithoutSaving}>
              <span>Close without Saving</span>
            </Button>
            {choosingPermissions ? (
              <>
                {selectedPerRole === '' ? (
                  <>
                    <Button
                      placeholder="."
                      variant="gradient"
                      color="green"
                      disabled
                      onClick={saveAccountsForNewPermissions}
                    >
                      <span>Save New Permissions</span>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      placeholder="."
                      variant="gradient"
                      color="green"
                      onClick={saveAccountsForNewPermissions}
                    >
                      <span>Save New Permissions</span>
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <Button
                  placeholder="."
                  variant="gradient"
                  color="green"
                  onClick={() => {
                    setChoosingPermissions(true)
                  }}
                >
                  <span>Next Step</span>
                </Button>
              </>
            )}
          </>
        </DialogFooter>
      </Dialog>
    </div>
  )
}

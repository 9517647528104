import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC12WuKA4JtHpKvgSmgAu3YOr0-E1qiF9c",
  authDomain: "osmg-internal.firebaseapp.com",
  projectId: "osmg-internal",
  storageBucket: "osmg-internal.appspot.com",
  messagingSenderId: "135476755407",
  appId: "1:135476755407:web:5b5d59e005a15dab3ab0ba",
  measurementId: "G-Z6LB5RC8V1"
};

export default firebaseConfig;

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
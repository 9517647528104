import { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button, IconButton, Typography } from '@material-tailwind/react'
import nav_routes from '../routes/nav_routes'
import { PermissionsContext } from '../contexts'


export default function SideBar() {
  const [collapsed, setCollapsed] = useState(true)
  const [currentPath, setPath] = useState<string>('')
  const permissions = useContext(PermissionsContext)

  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname)
  }, [location]);

  function toggleCollapse() {
    setCollapsed(!collapsed)
  }
  function checkPermissions(route_path) {
    // only some routes are protected.
    if (route_path === '/settings') {
      return permissions.view_settings
    }
    if (route_path === '/editor') {
      return permissions.edit_all_roles
    } else {
      return true
    }
  }
  return (
    <div className="h-full p-0" key="sidebar_container">
      {collapsed ? (
        <div
          key="collapsed"
          className="z-50 h-screen w-16 bg-gradient-to-br from-blue-900 to-blue-800"
        >
          <div className="p-1">
            <ul key="2" className=" flex flex-col">
              {nav_routes.map((route, index) => {
                if (route.navRoute === true) {
                  if (checkPermissions(route.path)) {
                    return (
                      <li key={String(index) + '_collapsed'}>
                        <div
                          className="flex align-middle justify-center"
                          key={route.path + '_' + index + '_subdiv'}
                        >
                          <Link
                            to={route.path}
                            key={route.path + '_' + index + '_link'}
                            replace
                          >
                            {currentPath === route.path ? (
                              <div
                                className="w-full p-4 text-black bg-white rounded-lg flex justify-center align-middle"
                              >
                                <div className="h-6 w-6">{route.icon}</div>
                              </div>
                            ) : (
                              <div
                                className="w-full p-4 text-white bg-transparent rounded-lg flex justify-center align-middle"
                              >
                                <div className="h-6 w-6">{route.icon}</div>
                              </div>
                            )}
                          </Link>
                        </div>
                      </li>
                    )
                  } else {
                    return <></>
                  }
                } else {
                  return <div key={index + '_collapsed'}></div>
                }
              })}
            </ul>
          </div>
          <div className="flex h-8 flex-row-reverse justify-center">
            <IconButton
              variant="text"
              color="white"
              size="lg"
              ripple={false}
              onClick={toggleCollapse}
              placeholder={'.'}
            >
              <ChevronRightIcon className="h-8 w-8 stroke-white" />
            </IconButton>
          </div>
        </div>
      ) : (
        <div
          key="expanded"
          className="z-50 h-screen w-56 bg-gradient-to-br from-blue-900 to-blue-800"
        >
          <div className="">
            <ul key="1" className="flex flex-col">
              {nav_routes.map((route, index) => {
                if (route.navRoute === true) {
                  if (checkPermissions(route.path)) {
                    return (
                      <li key={index + '_expanded'}>
                        <div
                          className="p-2"
                          key={route.path + '_' + index + '_subdiv'}
                        >
                          <Link
                            to={route.path}
                            key={route.path + '_' + index + '_link'}
                          >
                            {route.path === currentPath ? (
                              <Button
                                placeholder={'.'}
                                variant="gradient"
                                color="white"
                                className="w-full text-black"
                              >
                                <div className="flex flex-row">
                                  <div className=" flex h-7 basis-1/4">
                                    {route.icon}
                                  </div>
                                  <div className="flex basis-3/4 items-center justify-center">
                                    <Typography placeholder={'.'}>
                                      {route.title}
                                    </Typography>
                                  </div>
                                </div>
                              </Button>
                            ) : (
                              <Button
                                placeholder={'.'}
                                variant="text"
                                className="w-full text-white"
                              >
                                <div className="flex flex-row">
                                  <div className=" flex h-7 basis-1/4">
                                    {route.icon}
                                  </div>
                                  <div className="flex basis-3/4 items-center justify-center">
                                    <Typography placeholder={'.'}>
                                      {route.title}
                                    </Typography>
                                  </div>
                                </div>
                              </Button>
                            )}
                          </Link>
                        </div>
                      </li>
                    )
                  } else {
                    return <div></div>
                  }
                } else {
                  return <div key={index + '_expanded'}></div>
                }
              })}
            </ul>
          </div>
          <div className="flex h-8 flex-row-reverse justify-center">
            <IconButton
              placeholder={'.'}
              variant="text"
              color="white"
              size="lg"
              ripple={false}
              onClick={toggleCollapse}
            >
              <ChevronLeftIcon className="h-8 w-8 stroke-white" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  )
}

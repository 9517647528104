import { Button } from "@material-tailwind/react";
import { useState } from "react";
import { additionalInfo } from "../../../../../types/additionalInfo";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../../firebase";

export default function Comments(props: {
  allInfo: additionalInfo;
  reloadParent: Function;
  client_id: string;
}) {
  const [allInfo, setAllInfo] = useState(props.allInfo);
  const [editComments, setEditComments] = useState(false);
  function handleChangeComments(e) {
    setAllInfo({ ...allInfo, comments: e.target.value });
  }
  async function saveComments() {
    await setDoc(doc(db, "additional_client_info", props.client_id), allInfo).then(()=>{
        setTimeout(()=>{
            props.reloadParent()
        }, 100)
    })
  }
  async function exitWithoutSave() {
    props.reloadParent()
  }
  return (
    <div className="h-fit w-1/2 overflow-auto p-4">
      <div className="flex flex-row px-4">
        <div className="pr-6 text-blue-800 ">
          <strong>Comments</strong> <br />
        </div>
        {editComments ? (
          <div className="flex w-96 justify-between">
            <Button
              placeholder="."
              variant="text"
              className="p-1 text-gray-500"
              onClick={() => {
                setEditComments(false);
                saveComments();
              }}
            >
              Save Changes
            </Button>
            <Button
              placeholder="."
              variant="text"
              className="p-1 text-gray-500"
              onClick={() => {
                setEditComments(false);
                exitWithoutSave();
              }}
            >
              Exit without Saving
            </Button>
          </div>
        ) : (
          <>
            <Button
              placeholder="."
              variant="text"
              className="p-1 text-gray-500"
              onClick={() => {
                setEditComments(true);
              }}
            >
              Edit
            </Button>
          </>
        )}
      </div>
      <div className="h-40 max-h-fit w-full min-w-fit overflow-auto rounded-lg border border-gray-300 p-2 shadow-md">
        {editComments ? (
          <>
            <textarea
              itemType="text"
              className="justify-top flex h-full w-full whitespace-pre-wrap"
              value={allInfo.comments}
              placeholder="Type here..."
              onChange={handleChangeComments}
            ></textarea>
          </>
        ) : (
          <>
            {allInfo !== undefined && allInfo.comments !== undefined ? (
              <>
                {allInfo.comments !== "" ? (
                  <div className="whitespace-pre-wrap">{allInfo.comments}</div>
                ) : (
                  <div className="text-gray-700">No Comments at this time.</div>
                )}
              </>
            ) : (
              <div className="text-gray-500">No Comments at this time.</div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

import { Button } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { permission } from '../../../../types/permission'

export default function EditButton(props: {
  editMode: boolean
  setEditMode: Function
  permissions: permission
  isMyClient: boolean
}) {
  const editMode = props.editMode

  const [canEdit, setCanEdit] = useState<boolean | undefined>()
  function decideIfCanEdit() {
    if (
      props.permissions.edit_all_clients === true ||
      props.permissions.edit_all_roles ||
      props.permissions.edit_all_contacts ||
      (props.isMyClient &&
        (props.permissions.edit_assigned_clients ||
          props.permissions.edit_my_client_contacts ||
          props.permissions.edit_my_client_roles))
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (canEdit === undefined) {
      setCanEdit(decideIfCanEdit())
    }
  })

  function toggleEditMode() {
    props.setEditMode(!editMode)
  }

  return (
    <>
      {canEdit && (
        <Button
          placeholder="."
          onClick={toggleEditMode}
          variant="text"
          className="text-black"
        >
          {editMode ? <>Return to regular view</> : <>Edit Client</>}
        </Button>
      )}
    </>
  )
}

import {
  Button,
  Card,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { additionalInfo } from '../../../../../types/additionalInfo'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../../../../../firebase'

export default function Logins(props: {
  allInfo: additionalInfo
  reloadParent: Function
  client_id: string
}) {
  const [editLogins, setEditLogins] = useState(false)
  const [brokerCredentials, setBrokerCredentials] = useState({})
  const [otherService, setOtherService] = useState(false)
  const [passwordServicePresets, setPasswordServicePresets] = useState<
    string[]
  >([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [allInfo, setAllInfo] = useState(props.allInfo)
  const PasswordHeaders = [
    'Service Name',
    'Email / Username',
    'Password',
    'Access Level',
  ]
  async function saveLogins() {
    var logins = allInfo.logins
    logins = logins.flat()
    if (allInfo !== undefined && allInfo.logins !== undefined) {
      await setDoc(
        doc(db, 'additional_client_info', props.client_id),
        {
          logins: logins,
        },
        { merge: true }
      )
    }
    props.reloadParent()
  }
  function deleteLogin(index) {
    var current_logins = allInfo.logins
    delete current_logins[index]
    current_logins.flat()
    setAllInfo({
      ...allInfo,
      logins: current_logins,
    })
  }
  function handleChangeLogin(index, field_name, value) {
    var current_logins = allInfo.logins
    current_logins[index][field_name] = value
    setAllInfo({
      ...allInfo,
      logins: current_logins,
    })
  }
  async function getBrokerCredentials() {
    const docSnap_m6 = await getDoc(doc(db, 'settings', 'broker_login_m6'))
    const docSnap_stratum = await getDoc(
      doc(db, 'settings', 'broker_login_stratum')
    )
    var temp_credentials = {}
    if (docSnap_m6.exists()) {
      temp_credentials = {
        market6: docSnap_m6.data(),
      }
    } else {
      temp_credentials = {
        market6: { user: 'Not Found', password: 'Not Found' },
      }
    }

    if (docSnap_stratum.exists()) {
      temp_credentials['stratum'] = docSnap_stratum.data()
    } else {
      temp_credentials['stratum'] = docSnap_stratum.data()
    }
    setBrokerCredentials(temp_credentials)
  }
  async function toggleBrokerPortal() {
    if (allInfo.broker_portal !== undefined) {
      const newValue = !allInfo.broker_portal
      await setDoc(
        doc(db, 'additional_client_info', String(props.client_id)),
        { broker_portal: newValue },
        { merge: true }
      ).then(() => {
        props.reloadParent()
      })
    } else {
      alert(
        'There was an error adjusting broker credentials settings. Please reload the page and try again.'
      )
    }
  }
  async function getPasswordServicePresets() {
    await getDoc(doc(db, 'settings', 'login_sites')).then((doc) => {
      if (doc.exists()) {
        setPasswordServicePresets(doc.data().options)
      } else {
        alert("Couldn't find password presets.")
        setOtherService(true)
      }
    })
  }
  const stratum_access_levels = ['Broker Portal', 'Bronze', 'Silver', 'Gold', 'Platinum']

  useEffect(() => {
    if (firstLoad) {
      getBrokerCredentials()
      getPasswordServicePresets()
      setFirstLoad(false)
    }
  })

  return (
    <div className="min-h-40 h-fit w-fit overflow-auto p-4">
      <div className="flex flex-row px-4">
        <div className="pr-6 text-blue-800">
          <strong>Logins</strong> <br />
        </div>
        <div>
          {editLogins ? (
            <div className="flex w-96 justify-between">
              <Button
                placeholder="."
                variant="text"
                className="p-1 text-gray-500"
                onClick={() => {
                  setEditLogins(false)
                  saveLogins()
                }}
              >
                Save Changes
              </Button>
              <Button
                placeholder="."
                variant="text"
                className="p-1 text-gray-500"
                onClick={() => {
                  setEditLogins(false)
                  props.reloadParent()
                }}
              >
                Exit without Saving
              </Button>
            </div>
          ) : (
            <>
              <Button
                placeholder="."
                variant="text"
                className="p-1 text-gray-500"
                onClick={() => {
                  setEditLogins(true)
                }}
              >
                Edit
              </Button>
            </>
          )}
        </div>
      </div>
      <Card
        placeholder="."
        className="max-h-fit w-full min-w-fit overflow-clip p-0"
      >
        <table className="w-full min-w-fit table-auto text-left">
          <thead>
            <tr>
              {PasswordHeaders.map((head, index) => (
                <th key={head + index} className=" bg-blue-gray-50 px-4 py-4">
                  <Typography
                    placeholder="."
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
              {editLogins && (
                <th
                  key={'DeleteIcon'}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 px-2 py-4"
                >
                  <Typography
                    placeholder="."
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    Delete
                  </Typography>
                </th>
              )}
            </tr>
          </thead>
          {allInfo !== undefined && allInfo.logins !== undefined && (
            <tbody>
              {allInfo.logins.map((login, index) => {
                if (!editLogins) {
                  return (
                    <tr className="border-2" key={'login_row_' + index}>
                      <td className="p-1" key={'name ' + index}>
                        {login?.name}
                      </td>
                      <td className="p-1" key={'username ' + index}>
                        {login?.username}
                      </td>
                      <td className="p-1" key={'password ' + index}>
                        {login?.password}
                      </td>
                      <td className="p-1" key={'access_level ' + index}>
                        {login?.access_level}
                      </td>
                    </tr>
                  )
                } else {
                  return (
                    <tr className="border">
                      <td key={'name' + index}>
                        {otherService ? (
                          <input
                            value={login.name}
                            onChange={(e) => {
                              handleChangeLogin(index, 'name', e.target.value)
                            }}
                            className=""
                            placeholder="Name"
                          ></input>
                        ) : (
                          <Menu>
                            <MenuHandler>
                              <Button
                                placeholder={'.'}
                                variant="text"
                                className="p-1"
                              >
                                {login.name === '' ? (
                                  <>Name: </>
                                ) : (
                                  <>{login.name}</>
                                )}
                              </Button>
                            </MenuHandler>
                            <MenuList placeholder={'.'}>
                              {passwordServicePresets.map((title) => (
                                <MenuItem
                                  placeholder={'.'}
                                  onClick={() =>
                                    handleChangeLogin(index, 'name', title)
                                  }
                                >
                                  {title}
                                </MenuItem>
                              ))}
                              <MenuItem
                                placeholder={'.'}
                                onClick={() => setOtherService(true)}
                              >
                                Other
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </td>
                      <td key={'username' + index}>
                        <input
                          value={login.username}
                          onChange={(e) => {
                            handleChangeLogin(index, 'username', e.target.value)
                          }}
                          className=""
                          placeholder="example@gmail.com"
                        ></input>
                      </td>
                      <td key={'password' + index}>
                        <input
                          value={login.password}
                          onChange={(e) => {
                            handleChangeLogin(index, 'password', e.target.value)
                          }}
                          className=""
                          placeholder="Password123"
                        ></input>
                      </td>
                      <td key={'access_level' + index}>
                        {login.name !== 'Stratum' &&
                        login.name !== 'OnDemand' ? (
                          <input
                            value={login.access_level}
                            onChange={(e) => {
                              handleChangeLogin(
                                index,
                                'access_level',
                                e.target.value
                              )
                            }}
                            className=""
                            placeholder="Bronze"
                          ></input>
                        ) : (
                          <Menu>
                            <MenuHandler>
                              <Button
                                placeholder={'.'}
                                variant="text"
                                className="p-1"
                              >
                                {login.access_level === '' ? (
                                  <>Access Level: </>
                                ) : (
                                  <>{login.access_level}</>
                                )}
                              </Button>
                            </MenuHandler>
                            <MenuList placeholder={'.'}>
                              {stratum_access_levels.map((title) => (
                                <MenuItem
                                  placeholder={'.'}
                                  onClick={() =>
                                    handleChangeLogin(
                                      index,
                                      'access_level',
                                      title
                                    )
                                  }
                                >
                                  {title}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        )}
                      </td>
                      <td
                        onClick={() => {
                          deleteLogin(index)
                        }}
                        key={'Trash' + index}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </td>
                    </tr>
                  )
                }
              })}
              {allInfo.broker_portal && (
                <tr className="border-2" key={'login_row_broker_stratum'}>
                  <td className="p-1" key={'name'}>
                    Stratum
                  </td>
                  <td className="p-1" key={'username'}>
                    {brokerCredentials['stratum']?.user}
                  </td>
                  <td className="p-1" key={'password'}>
                    {brokerCredentials['stratum']?.password}
                  </td>
                  <td className="p-1" key={'access_level'}>
                    Broker Portal
                  </td>
                </tr>
              )}
              {allInfo.broker_portal && (
                <tr className="border-2" key={'login_row_market_6_broker'}>
                  <td className="p-1" key={'name'}>
                    OnDemand
                  </td>
                  <td className="p-1" key={'username'}>
                    {brokerCredentials['market6']?.user}
                  </td>
                  <td className="p-1" key={'password'}>
                    {brokerCredentials['market6']?.password}
                  </td>
                  <td className="p-1" key={'access_levels'}>
                    Broker Portal
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
        <div className="p-2">
          {editLogins && (
            <div>
              <Button
                placeholder="."
                variant="text"
                className="p-1"
                color="green"
                onClick={() => {
                  console.log('set new logins runnig. Before: ', allInfo.logins)
                  if (allInfo !== undefined && allInfo.logins !== undefined) {
                    var current_logins = allInfo.logins
                    current_logins.push({
                      name: '',
                      username: '',
                      password: '',
                      access_level: '',
                    })
                    setAllInfo({
                      ...allInfo,
                      logins: current_logins,
                    })
                  } else {
                    setAllInfo({
                      ...allInfo,
                      logins: [
                        {
                          name: '',
                          username: '',
                          password: '',
                          access_level: '',
                        },
                      ],
                    })
                  }
                }}
              >
                Add New Login
              </Button>
              {allInfo.broker_portal ? (
                <div>
                  <Button
                    placeholder="."
                    variant="text"
                    className="p-1"
                    color="blue"
                    onClick={toggleBrokerPortal}
                  >
                    Remove Broker Portal Logins
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    placeholder="."
                    variant="text"
                    className="p-1"
                    color="green"
                    onClick={toggleBrokerPortal}
                  >
                    Add Broker Portal Logins
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

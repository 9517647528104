import { useContext, useEffect, useState } from 'react'
import { AccountsContext } from '../contexts'
import { account } from '../types/account'
import { Link } from 'react-router-dom'

export default function AccountName(props: { account_id: string, accounts: account[] }) {
  const [account, setAccount] = useState<account | undefined>()
  function findAccount(account_id) {
    props.accounts.forEach((account: account) => {
      if (account.id === account_id) {
        setAccount(account)
      }
    })
  }
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad && props.accounts) {
      setFirstLoad(false)
      findAccount(props.account_id)
    }
  })

  return (
    <div className="p-0 h-fit w-fit">
      {account ? (
        <Link to={'/view/account#' + props.account_id}>
          <div className="p-3 text-center text-black">{account.name}</div>
        </Link>
      ) : (
        <div>n/a</div>
      )}
    </div>
  )
}

import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../../assets/img/osmg-logo-without-subtitle.png";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { Navigate } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Input,
  Typography,
} from "@material-tailwind/react";

function ResetPassword() {
  const [success, setSuccess] = useState(false);
  const [failedToSend, setFailedToSend] = useState(false);
  const [returnToSignIn, setReturn] = useState(false);
  const [email, setEmail] = useState("");
  const [newUser, setNewUser] = useState(false);
  const auth = getAuth();
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (firstLoad) {
      const tag = window.location.href.split("#").pop();
      if (tag === "new") {
        setNewUser(true);
      }
      setFirstLoad(false);
    }
  });
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleReturnToSignIn = () => {
    setReturn(true);
  };
  const handleSubmit = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        setSuccess(true);
        setFailedToSend(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error.code);
        console.log(error.message);
        alert("The Reset email failed to send. Please contact support if this issue persists.")
        setFailedToSend(true);
      });
  };

  return (
    <div
      className="flex h-screen w-screen flex-col "
      style={{ background: `linear-gradient(90deg, #075DB9, #3186e0)` }}
    >
      {returnToSignIn ? <Navigate to="/" replace /> : <></>}
      <div className="h-1/8 flex flex-1 flex-row-reverse">
        <img
          src={logo}
          style={{ width: "240px", height: "100px", padding: "20px" }}
        ></img>
      </div>
      <div
        className="flex h-full w-full flex-col justify-center p-10"
        style={{ background: `linear-gradient(transparent 50%, #ffffff 50%)` }}
      >
        <div className=" flex w-full flex-row justify-center align-top">
          <Card placeholder="." className=" max-w-1/4 min-w-30">
            <CardBody placeholder="." className="flex flex-col">
              {success ? (
                <>
                  <div className="p-2">
                    <h1 className=" text-center font-serif text-2xl font-semibold">
                      {newUser ? (
                        <>Email Successfully Sent!</>
                      ) : (
                        <>Reset Email Successfully Sent!</>
                      )}
                    </h1>
                  </div>
                  <hr></hr>
                  <div className="justify-center pt-6">
                    <Typography
                      placeholder="."
                      className="justify-center"
                      variant="small"
                    >
                      Follow the directions in the email to reset your password.
                      <br />
                    </Typography>
                  </div>
                  <div className="w-full p-6 pt-10">
                    <Button
                      placeholder="."
                      onClick={handleReturnToSignIn}
                      className="w-full"
                      variant="gradient"
                    >
                      Return to Sign In
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-2">
                    <h1 className=" text-center font-serif text-2xl font-semibold">
                      {newUser ? (
                        <>Enter Account Email</>
                      ) : (
                        <>Send Password Reset Email</>
                      )}
                    </h1>
                  </div>
                  <hr></hr>
                  <div>
                    {failedToSend ? (
                      <p className=" px-2 pb-5 pt-8 text-red-500">
                        Failed to send email. <br />
                        Please check you entered the correct email and Try
                        Again.
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="justify-center pt-6">
                    <Typography
                      placeholder="."
                      className="justify-center"
                      variant="small"
                    >
                      Please enter your email below. <br />A link will be sent
                      to your inbox so you can set a new password.
                    </Typography>
                  </div>
                  <div className="p-6">
                    <Input
                      crossOrigin={false}
                      variant="standard"
                      label="Email"
                      type="text"
                      size="lg"
                      value={email}
                      onChange={handleChangeEmail}
                    />
                  </div>
                  <div className="w-full p-6 pt-10">
                    <Button
                      placeholder="."
                      onClick={handleSubmit}
                      className="w-full"
                      variant="gradient"
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

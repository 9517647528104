import { Button, Card, Input, Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { db } from '../../firebase'
import { doc, getDoc, setDoc } from 'firebase/firestore'

export default function PresetsTab() {
  const [m6BrokerPassword, setM6BrokerPassword] = useState('')
  const [m6BrokerUser, setM6BrokerUser] = useState('')

  const [stratumBrokerPassword, setStratumBrokerPassword] = useState('')
  const [stratumBrokerUser, setStratumBrokerUser] = useState('')

  const [newM6BrokerPassword, setNewM6BrokerPassword] = useState(false)
  const [newStratumPassword, setNewStratumPassword] = useState(false)

  async function changeM6BrokerCred() {
    const response = window.confirm(
      'Are you sure you would like to set new broker credentials for OnDemand?'
    )
    if (response) {
      await setDoc(doc(db, 'settings', 'broker_login_m6'), {
        password: m6BrokerPassword,
        user: m6BrokerUser,
      })
    }
  }
  async function changeStratumBrokerCred() {
    const response = window.confirm(
      'Are you sure you would like to set new broker credentials for Stratum?'
    )
    if (response) {
      await setDoc(doc(db, 'settings', 'broker_login_stratum'), {
        password: stratumBrokerPassword,
        user: stratumBrokerUser,
      })
      setTimeout(() => {
        setFirstLoad(true)
      }, 500)
    }
  }
  async function getBrokerCred() {
    const docSnap_m6 = await getDoc(doc(db, 'settings', 'broker_login_m6'))
    if (docSnap_m6.exists()) {
      const data = docSnap_m6.data()
      setM6BrokerUser(data.user)
      setM6BrokerPassword(data.password)
    } else {
      setM6BrokerUser('')
      setM6BrokerPassword('')
    }
    const docSnap_stratum = await getDoc(
      doc(db, 'settings', 'broker_login_stratum')
    )
    if (docSnap_stratum.exists()) {
      const data = docSnap_stratum.data()
      setStratumBrokerUser(data.user)
      setStratumBrokerPassword(data.password)
    } else {
      setStratumBrokerUser('')
      setStratumBrokerPassword('')
    }
  }

  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    if (firstLoad) {
      getBrokerCred()
      setFirstLoad(false)
    }
  })

  return (
    <div className="flex h-full flex-col">
      <Typography placeholder={'.'} variant="h5" className="p-3">
        Presets
      </Typography>
      <Card placeholder={'.'} className="h-5/6 rounded-lg border">
        <div className="overflow-auto px-6 pb-4 pt-1">
          <div className="w-full">
            <div className="w-1/4">
              <Typography placeholder={'.'} className="h5 p-3">
                Broker Portal Credentials
              </Typography>
              <hr className=" border border-blue-800" />
            </div>
            <div className="flex w-full flex-row">
              <div className="w-1/3 px-3">
                <div>
                  <Typography
                    placeholder={'.'}
                    variant="h6"
                    className="px-4 pt-4"
                  >
                    Stratum
                  </Typography>
                </div>
                <div className="w-full pb-4 pt-6">
                  <Input
                    label="Username"
                    crossOrigin={false}
                    value={stratumBrokerUser}
                    onChange={(e) => {
                      setStratumBrokerUser(e.target.value)
                      setNewStratumPassword(true)
                    }}
                  />
                </div>
                <div className="w-full pb-6">
                  <Input
                    label="Password"
                    crossOrigin={false}
                    value={stratumBrokerPassword}
                    onChange={(e) => {
                      setStratumBrokerPassword(e.target.value)
                      setNewStratumPassword(true)
                    }}
                  />
                </div>
                <div>
                  {newStratumPassword ? (
                    <Button
                      placeholder="."
                      color="green"
                      onClick={changeStratumBrokerCred}
                    >
                      Submit Change
                    </Button>
                  ) : (
                    <Button placeholder="." variant="text">
                      Submit Change
                    </Button>
                  )}
                </div>
              </div>
              <div className="w-1/3 px-3">
                <div>
                  <Typography
                    placeholder={'.'}
                    variant="h6"
                    className="px-4 pt-4"
                  >
                    OnDemand
                  </Typography>
                </div>
                <div className="w-full pb-4 pt-6">
                  <Input
                    label="Username"
                    crossOrigin={false}
                    value={m6BrokerUser}
                    onChange={(e) => {
                      setM6BrokerUser(e.target.value)
                      setNewM6BrokerPassword(true)
                    }}
                  />
                </div>
                <div className="w-full pb-6">
                  <Input
                    label="Password"
                    crossOrigin={false}
                    value={m6BrokerPassword}
                    onChange={(e) => {
                      setM6BrokerPassword(e.target.value)
                      setNewM6BrokerPassword(true)
                    }}
                  />
                </div>
                <div>
                  {newM6BrokerPassword ? (
                    <Button
                      placeholder="."
                      color="green"
                      onClick={changeM6BrokerCred}
                    >
                      Submit Change
                    </Button>
                  ) : (
                    <Button placeholder="." variant="text">
                      Submit Change
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

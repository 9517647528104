import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Input,
  Typography,
  Avatar,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react'
import { useState, useEffect, useContext } from 'react'
import '../../App.css'
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import Loading from '../../components/Loading'
import {
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Link, Navigate } from 'react-router-dom'
import parseChange from '../../functions/parseChange'
import {
  AccountsContext,
  CurrentUserContext,
  ReloadDataContext,
} from '../../contexts'
import { db } from '../../firebase'
import { client } from '../../types/client'
import { roles } from '../../types/roles'
import { account } from '../../types/account'
import ensureClientType from '../../functions/ensureType/ensureClientType'
import clientTemplate from '../../functions/ensureType/templateClient'

export default function EditRoles() {
  // general
  const [firstLoad, setFirstLoad] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const current_account = useContext(CurrentUserContext)

  // accounts status variables
  const accounts = useContext(AccountsContext)

  const [key, setKey] = useState('')
  const reload = () => {
    setKey(String(Math.round(Math.random() * 100000)))
  }
  const toggleRedirect = () => {
    setRedirect(true)
  }

  const reloadData = useContext(ReloadDataContext)
  useEffect(() => {
    // runs if first load, and url has been parsed.
    if (firstLoad === true && accounts.length > 0) {
      const id = window.location.href.split('#').pop()
      getClient(id)
      getPresetRoles()
      reloadData()
      setFirstLoad(false)
    }
  }, [reloadData, getClient, getPresetRoles, setFirstLoad, firstLoad])

  // Initialize Firebase & Firestore
  async function getClient(id) {
    const client_doc = doc(db, 'clients', id)
    const unsub = onSnapshot(client_doc, (querySnap) => {
      if (querySnap.exists()) {
        const client = ensureClientType(querySnap.data())
        setClient(client)
        setClientArchive(client)
        setRoles(client.roles)
        setTitle(client.name + ' Roles')
      } else {
        // client not found
      }
    })
  }
  async function saveToClient() {
    var new_client_doc: client = client
    new_client_doc.roles = roles
    await parseChange(
      'client',
      client.id,
      clientArchive,
      new_client_doc,
      current_account
    ).then(() => {
      setDoc(doc(db, 'clients', client.id), new_client_doc)
      toggleRedirect()
    })
  }
  const [presetRoles, setPresetRoles] = useState<string[]>([])
  async function getPresetRoles() {
    const docSnap = await getDoc(doc(db, 'settings', 'roles'))
    if (docSnap.exists()) {
      var data = docSnap.data()
      setPresetRoles(data.options)
    }
  }

  // cleint status variables
  const [client, setClient] = useState<client>(clientTemplate())
  const [clientArchive, setClientArchive] = useState<client>(clientTemplate())

  // temporary roles object
  const [roles, setRoles] = useState<roles>({})

  // account selector dialog controls
  const [query, setQuery] = useState<string>('')
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<string>('')
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [dialogKey, setDialogKey] = useState<string>('')
  function reloadDialogContents() {
    setDialogKey(String(Math.round(Math.random() * 100000)))
  }
  function addAccount(role_key) {
    handleDialog()
    setSelectedRole(role_key)
  }
  function handleDialog() {
    setDialogOpen(!dialogOpen)
  }
  function saveAccountsToRole() {
    for (let i in selectedAccounts) {
      updateRoles(selectedAccounts[i])
    }
    function updateRoles(id) {
      var new_roles_object = roles
      if (!new_roles_object[selectedRole].includes(id)) {
        new_roles_object[selectedRole].push(id)
        setRoles(new_roles_object)
      }
    }
    reload()
    handleDialog()
    setSelectedAccounts([])
  }
  function exitWithoutSavingAccounts() {
    setSelectedAccounts([])
    handleDialog()
    reload()
  }
  function toggleAccountSelection(id: string) {
    var new_selectedAccounts_object: string[] = selectedAccounts
    if (!selectedAccounts.includes(id)) {
      new_selectedAccounts_object.push(id)
      setSelectedAccounts(new_selectedAccounts_object)
      reloadDialogContents()
    } else {
      var current_accounts = new_selectedAccounts_object
      var final_accounts: string[] = []
      for (let index in current_accounts) {
        if (current_accounts[index] !== id) {
          final_accounts.push(current_accounts[index])
        }
      }
      new_selectedAccounts_object = final_accounts
      setSelectedAccounts(new_selectedAccounts_object)
    }
  }
  function search() {
    var array_of_accounts: account[] = []
    for (let key in accounts) {
      array_of_accounts.push(accounts[key])
    }
    return array_of_accounts.filter((account) =>
      account.name.toString().toLowerCase().includes(query.toLowerCase())
    )
  }
  function handleQuery(e) {
    setQuery(e.target.value)
  }

  // new role
  const [roleName, setRoleName] = useState('')
  function handleRoleName(e) {
    setRoleName(e.target.value)
  }
  function createRole() {
    var new_roles = roles
    if (new_roles === undefined) {
      new_roles = {}
    }
    new_roles[roleName] = []
    setRoles(new_roles)
    reload()
  }
  function addExistingRole(new_role) {
    var new_roles = roles
    if (new_roles === undefined) {
      new_roles = {}
    }
    new_roles[new_role] = []
    setRoles(new_roles)
    reload()
  }
  function deleteRole(role_key) {
    var new_roles = {}
    for (let key in roles) {
      if (!(key === role_key)) {
        new_roles[key] = roles[key]
      }
    }
    setRoles(new_roles)
    reload()
  }

  // removing accts / roles
  function removeAccount(acct_id, role_title) {
    var new_roles_object: roles = roles
    var current_accounts: string[] = new_roles_object[role_title]
    var final_accounts: string[] = []
    for (let index in current_accounts) {
      if (current_accounts[index] !== acct_id) {
        final_accounts.push(current_accounts[index])
      }
    }
    new_roles_object[role_title] = final_accounts
    setRoles(new_roles_object)
    reload()
  }

  // local components
  function RolesDisplay() {
    return (
      <>
        {Object.keys(roles).map((key) => {
          return (
            <div key={'roles_display_' + key}>
              {rolesBlock(key, roles[key])}
            </div>
          )
        })}
      </>
    )
  }
  function rolesBlock(key, ids) {
    return (
      <>
        <div className="max-w-sm p-4" key={key + '_rolesBlock'}>
          <div className=" flex w-full flex-row">
            <Typography
              placeholder="."
              variant="h5"
              color="blue-gray"
              className="px-4"
            >
              {key}
            </Typography>
            <IconButton
              placeholder="."
              variant="text"
              onClick={() => {
                deleteRole(key)
              }}
            >
              <TrashIcon className="h-5 w-5" />
            </IconButton>
          </div>
          <hr className=" w-full border-2 border-blue-700" />
          <div className="">
            {ids?.map((id) => {
              var account_data = {}
              for (let index in accounts) {
                if (accounts[index].id === String(id)) {
                  account_data = accounts[index]
                }
              }
              try {
                return AccountCard(account_data, id, key)
              } catch {
                console.log('Could Not Render Account #' + id)
                return <></>
              }
            })}
          </div>
          <Button
            placeholder="."
            variant="text"
            className="p-0"
            onClick={() => {
              addAccount(key)
            }}
          >
            <div className="p-2">
              <Card
                placeholder="."
                className=" h-30 flex w-60 flex-row rounded-lg border-2 border-gray-400"
              >
                <div className="w-1/3 p-3">
                  <PlusIcon className="h-10 w-10" />
                </div>
                <div className="w-2/3 flex-grow pl-2 pt-3">
                  <Typography placeholder="." color="blue-gray">
                    Add New Account
                  </Typography>
                </div>
              </Card>
            </div>
          </Button>
        </div>
      </>
    )
  }
  function AccountCard(account, acct_id, role_title) {
    return (
      <div className="p-2 " key={acct_id + '_acct_card_' + role_title}>
        <Card
          placeholder="."
          className=" h-30 flex w-80 flex-row rounded-lg border-2 border-gray-400"
        >
          <div className="w-1/3">
            <Avatar
              placeholder="."
              src={account?.avatar}
              className="h-20 w-20 p-2"
            />
          </div>
          <div className="w-2/3 flex-grow pl-2 pt-3">
            <Typography placeholder="." variant="h5" color="blue-gray">
              <Link to={'/view/account#' + String(acct_id)}>
                {account?.name}
              </Link>
            </Typography>
            <Typography placeholder="." variant="small" color="blue-gray">
              {account?.phone}
            </Typography>
          </div>
          <IconButton
            placeholder="."
            variant="text"
            onClick={() => {
              removeAccount(acct_id, role_title)
            }}
          >
            <TrashIcon className="h-5 w-5" />
          </IconButton>
        </Card>
      </div>
    )
  }

  const TABLE_HEAD = ['Name', 'Phone #', 'Email', 'Title', '']
  const [title, setTitle] = useState('Single Client Roles')

  return (
    <>
      {redirect && <Navigate to={'/view/client#' + client.id} replace />}
      <Card
        placeholder="."
        className="flex h-fit flex-col rounded-sm"
        key={key}
      >
        <Dialog
          placeholder="."
          open={dialogOpen}
          handler={handleDialog}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          size="xl"
        >
          <DialogHeader placeholder=".">
            <div className="flex w-full justify-between p-0">
              <div>Select Accounts</div>
              <div>
                <Link to="/add/account">
                  <Button placeholder="." variant="outlined">
                    Add New Account
                  </Button>
                </Link>
              </div>
            </div>
          </DialogHeader>
          <DialogBody placeholder="." className="h-96 overflow-auto">
            <div className="w-50 flex flex-row pb-5">
              <Input
                crossOrigin={false}
                label="Search"
                value={query}
                onChange={handleQuery}
                icon={<MagnifyingGlassIcon />}
              />
            </div>
            <table
              className="w-full table-auto overflow-auto text-left border-2"
              key={dialogKey}
            >
              <thead>
                <tr key={'header_row'}>
                  {TABLE_HEAD.map((head, index) => (
                    <th
                      key={head + index}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        placeholder="."
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              {accounts.length < 0 ? (
                <>
                  <Typography placeholder="." className="p-5">
                    No Accounts were found.
                  </Typography>
                </>
              ) : (
                <>
                  {accounts !== undefined ? (
                    <tbody>
                      {search().map((account, index) => {
                        var classes = ''
                        if (selectedAccounts.includes(account.id)) {
                          classes = 'bg-blue-100'
                        } else {
                          classes = ''
                        }
                        return (
                          <tr
                            key={account.name + String(index)}
                            className={classes}
                          >
                            <td className="p-4">
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {account?.name}
                              </Typography>
                            </td>
                            <td className="p-4">
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {account.phone}
                              </Typography>
                            </td>
                            <td className="p-4">
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {account.email}
                              </Typography>
                            </td>
                            <td className="p-4">
                              <Typography
                                placeholder="."
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {account.title}
                              </Typography>
                            </td>
                            <td className={` bg-blue-gray-50/50`}>
                              <div className="flex w-full justify-center pl-2">
                                <Button
                                  placeholder="."
                                  variant="text"
                                  color="blue"
                                  onClick={() => {
                                    toggleAccountSelection(account.id)
                                  }}
                                >
                                  Select
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                    <>
                      <div className="p-3">
                        <Loading />
                      </div>
                    </>
                  )}
                </>
              )}
            </table>
          </DialogBody>
          <DialogFooter placeholder=".">
            <Button
              placeholder="."
              variant="text"
              onClick={exitWithoutSavingAccounts}
            >
              <span>Close without Saving</span>
            </Button>
            <Button
              placeholder="."
              variant="gradient"
              color="green"
              onClick={saveAccountsToRole}
            >
              <span>Add Accounts to Role</span>
            </Button>
          </DialogFooter>
        </Dialog>
        {client === undefined ? (
          <div className="p-6">
            <Loading />
          </div>
        ) : (
          <>
            {client === undefined ? (
              <div>Could not find client details.</div>
            ) : (
              <div className="flex h-full w-full flex-row flex-wrap">
                <div className="w-8/12 max-w-max p-4 pt-8">
                  <div className="flex flex-col flex-wrap">
                    <Typography placeholder="." variant="h4" className="pb-2">
                      Roles
                    </Typography>
                    <hr></hr>
                    <div className="flex flex-row flex-wrap">
                      {firstLoad || roles === undefined ? (
                        <></>
                      ) : (
                        <>
                          <RolesDisplay />
                        </>
                      )}
                      <div className="max-w-sm p-4" key="add_row_input">
                        <Typography
                          placeholder="."
                          variant="h5"
                          color="blue-gray"
                          className="px-4"
                        >
                          Add Role
                        </Typography>
                        <hr className=" w-full border-2 border-green-700" />
                        <div className="flex flex-row p-2">
                          <Menu>
                            <MenuHandler>
                              <Button placeholder="." variant="outlined">
                                Choose from Presets
                              </Button>
                            </MenuHandler>
                            <MenuList placeholder=".">
                              {presetRoles.map((role, index) => {
                                return (
                                  <MenuItem
                                    placeholder="."
                                    key={index + '_role_option'}
                                    onClick={() => {
                                      addExistingRole(role)
                                    }}
                                  >
                                    {role}
                                  </MenuItem>
                                )
                              })}
                            </MenuList>
                          </Menu>
                        </div>
                      </div>
                      <div className="max-w-sm p-4" key="add_custom_row_input">
                        <Typography
                          placeholder="."
                          variant="h5"
                          color="blue-gray"
                          className="px-4"
                        >
                          Add Custom Role
                        </Typography>
                        <hr className=" w-full border-2 border-green-700" />
                        <div className="flex flex-row p-2">
                          <Input
                            crossOrigin={false}
                            value={roleName}
                            onChange={handleRoleName}
                            label="New Role Title"
                          ></Input>
                          <IconButton
                            placeholder="."
                            className="p-1"
                            variant="text"
                            onClick={createRole}
                          >
                            <PlusIcon className="h-5 w-5"></PlusIcon>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className="w-fit p-4">
          <Button
            placeholder="."
            onClick={saveToClient}
            className="w-1/6 min-w-fit"
          >
            Submit Changes
          </Button>
        </div>
      </Card>
    </>
  )
}

import { DocumentData, Firestore, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore"
import { client } from "../types/client"
import findClientIdsForAccount from "./findClientIdsForAccount"
import { db } from "../firebase"
import findClientFileIdsForAccount from "./findClientFileIdsForAccount"

export default async function findAccountComms(clients: client[], account_id: string) {
  // upc docs have the title of their respective client ID once editted, but rely on the file ids from import.
  const client_ids:string[] = findClientIdsForAccount(clients, account_id, true)
  const file_ids:string[] = findClientFileIdsForAccount(clients, account_id, true)
  const possible_upc_doc_names: string[] = client_ids.concat(file_ids)
  
  var comm_numbers: number[] = []

  var upcDocs: DocumentData[] = []

  const upcsRef = collection(db, 'upcDocs')
  const querySnapshot = await getDocs(upcsRef);
  querySnapshot.forEach((doc)=>{
    if (doc.exists() && doc.id in possible_upc_doc_names) {
      upcDocs.push(doc.data())
    }
  })
  upcDocs.forEach((doc)=>{
    if (doc.upcs) {
      const upc_obj = doc.upcs
      Object.keys(upc_obj).forEach((upc) => {
        if (upc_obj[upc].kr_comm && upc_obj[upc].kr_comm !== "") {
          const comm = upc_obj[upc].kr_comm
          if (!comm_numbers.includes(comm)) {
            comm_numbers.push(Number(comm))
          }
        }
      })
    }
  })
  console.log("Loading Comms for User.", comm_numbers)
  return comm_numbers
}
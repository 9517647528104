import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import { client } from "../types/client";
import { account } from "../types/account";

export default async function parseChange(
  subject_type: "client" | "account",
  subject_id: string,
  old_object: client | account,
  new_object: client | account,
  current_user_account: account,
) {
  try {
    console.log(new_object, old_object)

    // set account ID
    var author_id = current_user_account.id;

    // testing new change process.
    const testing_change = {
      "author_id": author_id,
      "new_object": new_object,
      "old_object": old_object,
      "subject_id": subject_id,
      "subject_type": subject_type,
      "testing": false
    }
    await addDoc(collection(db, "unprocessed_changes"), testing_change)


  } catch (err) {
    console.log(err);
  }
}


/*
previous code: 

######



    // set timestamp
    const currentDate = new Date();

    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear();

    const timestamp =
      currentMonth + 1 + "-" + currentDayOfMonth + "-" + currentYear;
    // "11-27-2020" primarily for display not sorting.

    const dateOfAction = Date.now();
    var deletionDay = 0;
    var deletionMonth = 0;
    var deletionYear = 2000;


    if (currentMonth < 11) {
      deletionMonth = currentMonth + 1
    } else {
      deletionMonth = 0
    }
    if (currentDayOfMonth < 28) {
      deletionDay = currentDayOfMonth
    } else {
      deletionDay = 28
    }
    const deletionDate = new Date(deletionYear, deletionMonth, deletionDay) // one month after creation.
    
    // set default object details.
    const change_log_template = {
      author_id: author_id,
      subject_type: subject_type,
      subject_id: subject_id,
      timestamp: timestamp,
      expiration: deletionDate, 
      actionDate: dateOfAction,
      field_name: "",
      old_value: "",
      new_value: "",
    };

    // allows for quick submission in the coming logic gates.
    async function submitChange(field_name, old_value, new_value) {
      var change_log_doc = change_log_template;
      change_log_doc.field_name = field_name;
      change_log_doc.old_value = old_value;
      change_log_doc.new_value = new_value;
      await addDoc(collection(db, "change_log"), change_log_doc);
    }

    // send a submission for each deletion, creation or change from the old to new objects.
    for (let field in old_object) {
      var new_array: string[];
      var old_array: string[];
      var additions: string[];
      var removed: string[];
      if (field === "roles" || field === "contacts") {
        var old_dict = old_object[field];
        var new_dict = new_object[field];
        for (let key in old_dict) {
          old_array = old_dict[key];
          if (new_dict[key] === undefined) {
            await submitChange(
              String(field) + "/" + String(key),
              String(old_dict[key]),
              "blank",
            );
          } else {
            new_array = new_dict[key];
            additions = [];
            removed = [];
            for (let index in old_array) {
              if (!new_array.includes(String(old_array[index]))) {
                removed.push(old_array[index]);
              }
            }
            for (let index in new_array) {
              if (!old_array.includes(String(new_array[index]))) {
                additions.push(new_array[index]);
              }
            }
            for (let index in additions) {
              await submitChange(
                String(field),
                "blank",
                String(additions[index]),
              );
            }
            for (let index in removed) {
              await submitChange(
                String(field),
                String(removed[index]),
                "blank",
              );
            }
          }
        }
        for (let key in new_dict) {
          if (old_dict[key] === undefined) {
            await submitChange(
              String(field) + "/" + String(key),
              "blank",
              String(old_dict[key]),
            );
          }
        }
      } else {
        if (typeof old_object[field] === "object") {
          new_array = new_object[field];
          old_array = old_object[field];
          additions = [];
          removed = [];
          for (let index in old_array) {
            if (!new_array.includes(String(old_array[index]))) {
              removed.push(old_array[index]);
            }
          }
          for (let index in new_array) {
            if (!old_array.includes(String(new_array[index]))) {
              additions.push(new_array[index]);
            }
          }
          for (let index in additions) {
            await submitChange(
              String(field),
              "blank",
              String(additions[index]),
            );
          }
          for (let index in removed) {
            await submitChange(String(field), String(removed[index]), "blank");
          }
        } else if (old_object[field] !== new_object[field]) {
          if (new_object[field] === undefined) {
            await submitChange(
              String(field),
              String(old_object[field]),
              "blank",
            );
          } else if (old_object[field] === undefined) {
            await submitChange(
              String(field),
              "blank",
              String(new_object[field]),
            );
          } else {
            await submitChange(
              String(field),
              String(old_object[field]),
              String(new_object[field]),
            );
          }
        }
      }
    }
*/
import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react'
import PermissionsOverivew from './PermissionsOverview'
import PermissionsAccessManagement from './PermissionsAccessManagement'

export default function PermissionsTab() {

  return (
    <Tabs value={'overview'}>
      <TabsHeader placeholder={'.'}>
        <Tab placeholder={'.'} key={'overview'} value={'overview'}>
          <div>Overview</div>
        </Tab>
        <Tab placeholder={'.'} key={'manage_access'} value={'manage_access'}>
          <div>Manage Access</div>
        </Tab>
      </TabsHeader>
      <TabsBody placeholder={'.'}>
        <TabPanel value={'overview'}>
          <PermissionsOverivew />
        </TabPanel>
        <TabPanel value={'manage_access'}>
          <PermissionsAccessManagement />
        </TabPanel>
      </TabsBody>
    </Tabs>
  )
}

import { useContext, useEffect, useState } from 'react'
import { ClientsContext, CurrentUserContext } from '../../contexts'
import findAccountComms from '../../functions/findAccountComms'
import Kompass from './KompassPage'

export default function KompassHandler() {
  const [checkedForComms, setCheckedForComms] = useState<boolean>(false)
  const [currentUserComms, setCurrentUserComms] = useState<number[]>([])
  async function updateCurrentUserComms(clients, currentUser) {
    const comms = await findAccountComms(clients, currentUser.id)
    setCurrentUserComms(comms) 
  }
  const clients = useContext(ClientsContext)
  const currentUserAccount = useContext(CurrentUserContext)

  useEffect(() => {
    if (!checkedForComms && clients && currentUserAccount) {
      setCheckedForComms(true)
      updateCurrentUserComms(clients, currentUserAccount)
      console.log("useEffect on Kompass Handler is running.")
    }
  }, [checkedForComms, currentUserComms, clients, currentUserAccount])

  return <Kompass userComms={currentUserComms} />
}

import { useContext, useEffect, useState } from 'react'
import { Card } from '@material-tailwind/react'
import ExcelLikeTable from '../components/ExcelLikeTable'
import { AccountsContext, ClientsContext, ReloadDataContext } from '../contexts'
import { client } from '../types/client'
import Loading from '../components/Loading'
import FindAndReplace from '../components/FindAndReplace'
import MatchAndReplace from '../components/MatchAndReplace'
import EditBySelection from '../components/EditBySelection'

export default function Editor() {
  type tableLine = {
    id: string
    Name: string
    VP: string[] // this will be an array of account ids.
    Director: string[]
    'Business Manager': string[]
    'Assistant Business Manager': string[]
    Insights: string[]
    'Assistant Insights Manager': string[]
    Orders: string[]
    Claims: string[]
    Assistant: string[]
    Marketing: string[]
  }

  const clients = useContext(ClientsContext)
  const [tableData, setTableData] = useState<tableLine[]>([])

  function parseTableData() {
    var data: tableLine[] = []
    clients.forEach((clientDoc: client) => {
      var clientLine: tableLine = {
        id: '',
        Name: '',
        VP: [],
        Director: [],
        'Business Manager': [],
        'Assistant Business Manager': [],
        Insights: [],
        'Assistant Insights Manager': [],
        Orders: [],
        Claims: [],
        Assistant: [],
        Marketing: [],
      }
      clientLine['Name'] = clientDoc.name
      clientLine['id'] = clientDoc.id
      Object.keys(clientDoc.roles).forEach((key) => {
        clientLine[key] = clientDoc.roles[key]
      })
      data.push(clientLine)
    })
    setTableData(data)
    setTableLoading(false)
  }

  function resetPage() {
    setEditMode('')
  }
  const accounts = useContext(AccountsContext)
  const reloadData = useContext(ReloadDataContext)
  const [editMode, setEditMode] = useState('')
  const [selections, setSelections] = useState<string[]>([])
  const [tableLoading, setTableLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  
  useEffect(() => {
    if (firstLoad && clients && reloadData) {
      reloadData()
      parseTableData()
      setFirstLoad(false)
    }
  })

  return (
      <Card placeholder={'.'} className="p-4 rounded-md">
        {tableLoading && tableData === undefined ? (
          <Loading />
        ) : (
          <div>
            {editMode === '' ? (
              <ExcelLikeTable
                accounts={accounts}
                data={tableData}
                setEdit={setEditMode}
                setSelectedClients={setSelections}
              />
            ) : (
              <>
                {editMode === 'find_and_replace' && (
                  <FindAndReplace
                    backArrow={resetPage}
                  />
                )}
                {editMode === 'selection' && (
                  <EditBySelection
                    backArrow={resetPage}
                    selectedClients={selections}
                  />
                )}
                {editMode === 'match_and_replace' && (
                  <MatchAndReplace
                    backArrow={resetPage}
                  />
                )}
              </>
            )}
          </div>
        )}
      </Card>
  )
}

import { client } from "../types/client";

export default function swapValueForNameIfClientID(value: string, clients: client[]) {
  var return_value = value

  clients.forEach((client: client)=>{
    if (value === client.id) {
      return_value = client.name
    }
  })
  if (return_value !== "") {
  return return_value} else {
    return "Unknown"
  }
}
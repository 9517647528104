import { Typography } from "@material-tailwind/react";


export default function WeeklyReportingEdit()
{
  return <>
    <Typography placeholder={"."} variant="h3">
      Weekly Reporting Edit
    </Typography>
    <>Placeholders</>
  </>
}
import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  ColorPicker,
  Dropdown,
  InputNumber,
  Row,
  Select,
  Slider,
  Upload,
} from 'antd'
import { utils, read } from 'xlsx'

export default function PogMapper() {
  const [data, setData] = useState([])
  const [graphData, setGraphData] = useState()
  const [headers, setHeaders] = useState([])
  const [fieldMatches, setFieldMatches] = useState({
    shelf: '',
    facings: '',
    height: '',
    width: '',
    'color value': '',
    upc: '',
    desc: '',
  })
  const [shelves, setShelves] = useState()
  const [selectOptions, setSelectOptions] = useState([])

  async function processFile(selectedFile) {
    try {
      const file = await selectedFile.arrayBuffer()
      const workbook = read(file)
      const first_sheet = workbook.Sheets[workbook.SheetNames[0]]
      const results = utils.sheet_to_json(first_sheet, { header: 1 })

      var dict_shaped_results = []
      var headers = []
      results.forEach((row, index) => {
        if (index === 0) {
          headers = row
        } else {
          var new_line = {}

          headers.forEach((header, index_h) => {
            new_line[header] = row[index_h] ? row[index_h] : ''
          })
          dict_shaped_results.push(new_line)
        }
      })
      setData(dict_shaped_results)
      setHeaders(headers)

      var selectOptions = []
      headers.forEach((header) => {
        selectOptions.push({
          value: header,
          label: header,
        })
      })
      setSelectOptions(selectOptions)

      console.log(dict_shaped_results)
    } catch (err) {
      console.log(err)
      alert('There was an error parsing your file.')
    }
  }
  const onFileChange = (event) => {
    try {
      processFile(event.target.files[0])
    } catch (err) {
      alert('There was an error uploading your file.')
    }
  }

  const [graph, setGraph] = useState(false)
  const handleSelectChange = (key, new_value) => {
    console.log(key, new_value)
    setFieldMatches({
      ...fieldMatches,
      [key]: new_value,
    })
    console.log(fieldMatches)

    var ready_to_graph = true
    Object.keys(fieldMatches).forEach((key_1) => {
      if (fieldMatches[key_1] === '' && key !== key_1) {
        ready_to_graph = false
      }
    })
    if (ready_to_graph) {
      setGraph(true)
    } else {
      setGraph(false)
    }

    refreshSelectSet()
  }
  const [selectSetKey, setSelectSetKey] = useState('1')

  useEffect(() => {
    if (graph) {
      setupGraph(fieldMatches)
      setGraph(false)
    }
  })
  const refreshSelectSet = () => {
    setSelectSetKey(String(Number(selectSetKey) + 1))
  }
  const fields = [
    'shelf',
    'facings',
    'height',
    'width',
    'color value',
    'upc',
    'desc',
  ]

  function setupGraph(fieldMatches) {
    var shelves_temp = []
    var graphData_temp = []
    var highest_color_value = 1
    data.forEach((data_obj) => {
      if (!shelves_temp.includes(data_obj[fieldMatches.shelf])) {
        shelves_temp.push(data_obj[fieldMatches.shelf])
      }
      if (Number(data_obj[fieldMatches['color value']]) > highest_color_value) {
        highest_color_value = Number(data_obj[fieldMatches['color value']])
      }
    })
    setHighestColorValue(highest_color_value)
    data.forEach((data_obj) => {
      var graphItem = {}
      Object.keys(fieldMatches).forEach((key) => {
        graphItem[key] = data_obj[fieldMatches[key]]
      })
      graphData_temp.push(graphItem)
    })
    console.log(shelves_temp, graphData_temp)
    setShelves(shelves_temp)
    setGraphData(graphData_temp)
  }

  const [highColor, setHighColor] = useState('')
  const [lowColor, setLowColor] = useState('')

  const [highestColorValue, setHighestColorValue] = useState(0)
  const [sizeMultiplier, setSizeMultiplier] = useState(20)
  function GraphItem({ item }) {
    console.log(item['color value'], highestColorValue)
    const background_hex = interpolateColor(
      highColor,
      lowColor,
      Number(item['color value']) / Number(highestColorValue)
    )
    var item_displays = []
    var counter = 0

    while (counter < item.facings) {
      counter += 1
      item_displays.push(
        <div
          key={item.desc + String(counter)}
          style={{
            border: '1px solid #000',
            width: `${Number(item.width) * sizeMultiplier}px`,
            height: `${Number(item.height) * sizeMultiplier}px`,
            margin: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: `${background_hex}`,
          }}
        >
          <div className="text-xs w-full overflow-visible">
            {item.desc}
            <br></br>
            {item.upc}
          </div>
        </div>
      )
    }

    return <div className="flex flex-row">{item_displays}</div>
  }

  function interpolateColor(color1, color2, factor) {
    console.log(color1, color2, factor)
    try {
      // Ensure factor is a number between 0 and 1
      if (factor < 0) factor = 0
      if (factor > 1) factor = 1

      // Helper function to convert hex to RGB
      function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16)
        const r = (bigint >> 16) & 255
        const g = (bigint >> 8) & 255
        const b = bigint & 255
        return [r, g, b]
      }

      // Helper function to convert RGB to hex
      function rgbToHex(r, g, b) {
        return (
          '#' +
          [r, g, b]
            .map((x) => {
              const hex = x.toString(16)
              return hex.length === 1 ? '0' + hex : hex
            })
            .join('')
        )
      }

      // Get RGB values for both colors
      const rgb1 = hexToRgb(color1)
      const rgb2 = hexToRgb(color2)

      // Interpolate between each color channel
      const r = Math.round(rgb1[0] + factor * (rgb2[0] - rgb1[0]))
      const g = Math.round(rgb1[1] + factor * (rgb2[1] - rgb1[1]))
      const b = Math.round(rgb1[2] + factor * (rgb2[2] - rgb1[2]))

      // Convert back to hex
      return rgbToHex(r, g, b)
    } catch (err) {
      console.log(err)
      return '#ffffff'
    }
  }

  const changeMultiplier = (event) => {
    setSizeMultiplier(event)
  }
  return (
    <div className="w-full h-full flex flex-col bg-white">
      <div className="p-2">Item PogMapper</div>
      <div className="px-2 py-4">
        <input type="file" onChange={onFileChange} />
      </div>
      <div className="flex flex-col w-fit p-4">
        Highest Color:
        <ColorPicker
          defaultValue="#1677ff"
          onChange={(event) => setHighColor(event.toHexString())}
        />
        Lowest Color:
        <ColorPicker
          defaultValue="#1677ff"
          onChange={(event) => setLowColor(event.toHexString())}
        />
      </div>
      <div>
        <Row>
          <Col span={12}>
            <Slider
              min={1}
              max={200}
              onChange={changeMultiplier}
              value={typeof sizeMultiplier === 'number' ? sizeMultiplier : 0}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={1}
              max={200}
              style={{ margin: '0 16px' }}
              value={sizeMultiplier}
              onChange={changeMultiplier}
            />
          </Col>
        </Row>
      </div>
      {data.length > 0 && (
        <div>
          <div className="w-44" key={selectSetKey}>
            {fields.map((key, index) => {
              return (
                <div className="w-60 py-2" key={index}>
                  <div className="w-full">{key}</div>
                  <div className="w-full">
                    <Select
                      className="w-full"
                      options={selectOptions}
                      defaultValue={fieldMatches[key]}
                      onChange={(event) => handleSelectChange(key, event)}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}

      {graphData && (
        <>
          <div className="flex flex-col w-full h-full">
            {shelves.map((shelf_num) => {
              return (
                <div className="flex flex-col w-full">
                  <hr></hr>
                  <div className="flex flex-row align-bottom">
                    {graphData.map((graphItem) => {
                      if (graphItem.shelf === shelf_num) {
                        return <GraphItem item={graphItem} />
                      }
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
      {/*
        shelves.map((shelf_num) => {
          return <>
            Shelf {shelf_num}
            <div className="flex flex-row w-fit p-2">
              {data.map((data_item, index) => {
                if (data_item.shelf === shelf_num) {
                  return <div
                    key={index}
                    style={{
                      border: '1px solid #000',
                      width: `${data_item.width}px`,
                      height: `${data_item.height}px`,
                      margin: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <p>{data_item.upc}</p>
                  </div>
                } else {
                  return <></>
                }
              })}
            </div>
          </>
        })*/}
    </div>
  )
}
